<template>
  <div class="page">
    <!-- <div class="topData">
      <div class="dataBuju">
        <div class="dataRow">
            <span class="textNum">
              <countTo class="textNum" :startVal="0" :endVal="ECShopCheckAvgValue.check_avg_value" />
              <span class="textHome">/家</span>
            </span>
            <span class="textHecha">日均核查</span>
        </div> -->
    <!-- <div class="dataRow">
        <span class="textNum">123<span class="textHome">/家</span></span>
        <span class="textHecha">人均核查</span>
    </div> -->
    <!-- <div class="dataRow">
        <span class="textNum">
          <countTo class="textNum" :startVal="0" :endVal="ECShopCheckAvgValue.complete_day" />
          <span class="textHome">/天</span>
        </span>
        <span class="textHecha">预估核查完毕需要</span>
    </div>
    <div class="dataRow">
        <span class="textNum">
          <countTo class="textNum" :startVal="0" :endVal="ECShopCheckAvgValue.today_new_input" />
          <span class="textHome">/家</span>
        </span>
        <span class="textHecha">今日录入新填报</span>
    </div>
  </div>
</div> -->

    <!-- <div class="managementType">
      <s-section-title-bar>电子烟生产经营主体类型</s-section-title-bar>
    </div>
    <div id="managementTypeChar" class="managementType_Char"></div> -->

    <!-- <div class="managementTypeTwo">
      <s-section-title-bar>各下级单位电子烟生产经营主体数量</s-section-title-bar>
    </div>
    <div id="managementCityChar" class="managementCity_Char"></div> -->

    <div class="tableData_hecha">
      <div class="bottom">
        <s-section-title-bar>核查记录</s-section-title-bar>
        <div
          class="tableData_style"
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(15,36,87,.85)">
          <el-table class="tableData"
                    :data="TodayECShopCheckRecordList.data"
                    @cell-mouse-leave="handleMouseLeave"
                    @cell-mouse-enter="handleMouseEnter">
            <el-table-column prop="img" label="整体外观" min-width="85" align="center">
              <template slot-scope="scope">
                <el-popover
                  placement="right"
                  width="100%"
                  :visible-arrow="false"
                  trigger="hover">
                  <img class="appearanceHover" :src="scope.row.present_status_overall_appearance_img_url" alt="">
                  <img slot="reference" class="appearance" :src="scope.row.present_status_overall_appearance_img_url"
                       alt="">
                </el-popover>

              </template>
            </el-table-column>
            <el-table-column prop="address" label="主体名称" min-width="160" align="center">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.main_body_name_all" placement="top-start">
                  <span class="address">{{ scope.row.main_body_name_all }}</span>
                </el-tooltip>

              </template>
            </el-table-column>
            <el-table-column prop="realname" label="核查人" min-width="80" align="center"></el-table-column>
            <el-table-column prop="created_time" label="核查时间" min-width="100" align="center"></el-table-column>
            <el-table-column prop="check_count" label="核查次数" min-width="80" align="center"></el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              small
              @current-change="pageChange"
              :background="true"
              :page-size="pageSize"
              :current-page="startPage"
              layout="prev, pager, next"
              :total="totalUsers">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Charts} from "@/views/home/charts";
import {mapState} from "vuex"

export default {
  name: 'electronic_right',
  data() {
    return {
      pageSize: 5, //每页几行
      startPage: 1, //起始页
      totalUsers: 30, //总行数,
      timer: null,
    }
  },
  computed: {
    ...mapState({
      loading: state => state.electronic.getEcShopListLoading,
      org_area_id: state => state.user.org_area_id,
      ECShopCheckAvgValue: state => state.electronic.ECShopCheckAvgValue,
      TodayECShopCheckRecordList: state => state.electronic.TodayECShopCheckRecordList,
    }),
  },
  watch: {
    // 默认展示最新检查数据的信息
    TodayECShopCheckRecordList(newVal, oldVal) {
      if (newVal.data.length !== 0 && this.startPage === 1) {
        const id = newVal.data[0].id;
        this.$store.dispatch("getECExamineDetail", {id});
      }
      this.totalUsers = newVal.page_info.total
    },

    org_area_id(newVal) {
      const params = { page:1, limit:5, org_area_id: newVal };
      this.$store.dispatch('getTodayECShopCheckRecordList', params);
    },
  },
  mounted() {},

  methods: {
    //分页 pageChange当前页发生改变触发函数
    pageChange(page) {
      this.startPage = page
      let params = {
        'limit': this.pageSize,
        'page': this.startPage
      }
      //调用获取列表的方法
      this.$store.dispatch('getTodayECShopCheckRecordList', params)
    },

    // 悬浮table的item项, 请求该项的详情数据
    handleMouseEnter({id}) {
      this.timer = setInterval(() => {
        this.$store.dispatch("getECExamineDetail", {id});
        this.$store.commit("setIsLookDotFlag", true);
        clearInterval(this.timer);
        this.timer = null;
      }, 2000);
    },

    handleMouseLeave() {
      clearInterval(this.timer);
      this.timer = null;
      this.$store.commit("setIsLookDotFlag", false);
    },


  }
}
</script>

<style lang="scss" scoped>

.topData {
  height: 200px;
  margin-left: 10px;
  background-image: url('https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/kuang.png');
  background-size: 98% 200px;
  background-repeat: no-repeat;
}

.dataBuju {
  margin-top: -5px;
  height: 200px;
  display: flex;
  justify-content: space-around;
  padding-top: 70px;
}

.dataRow {
  display: flex;
  flex-direction: column;
}

.textNum {
  font-size: 20px;
  font-weight: normal;
  color: #FFF;
}

.textHome {
  font-size: 13px;
  font-weight: 400;
  color: #81A9F1;
}

.textHecha {
  margin-top: 10px;
  font-size: 14px;
  width: 60px;
  color: #81A9F1;
}

.managementType {
  margin-top: 50px;
  margin-left: 20px;
}

.managementType_Char {
  margin-top: 10px;
  width: 100%;
  height: 220px;
  font-size: 14px;
  font-weight: 400;
}

.managementTypeTwo {
  margin-top: 30px;
  margin-left: 20px;
}

.managementCity_Char {
  margin-top: 20px;
  width: 100%;
  height: 400px;
  font-size: 14px;
  font-weight: 400;
}

.bottom {
  margin-top: 20px;

}

.tableData_style {
  width: 98%;
  margin-left: -10px;
  margin-top: 20px;
}

.tableData_hecha {
  padding-left: 25px;
  margin-top: 30px;
}

.tableData {
  width: 100%;
  min-height: 280px;
  font-size: 12px;
  font-weight: 400;
  color: #9DB9EB;
  background-color: #9DB9EB;
}

.appearanceHover {
  display: block;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.appearance {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  object-fit: cover;
}

.pagination {
  display: flex;
  justify-content: center;
  height: 23px;
  margin-top: 10px;
}

.address {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  outline-style: none;
}

::v-deep .el-loading-mask {

  .el-loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    transform: translateY(-50%);
  }

  .el-icon-loading {
    position: relative;
    width: 20Px;
    height: 20Px;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 20px;
    }
  }

  .el-loading-text {
    font-weight: 500;
    font-size: 12px;
  }
}

</style>
<style scoped>
.tableData_hecha /deep/ .el-table--fit {

}

.tableData_hecha /deep/ .el-table, .el-table__expanded-cell {
  background-color: transparent;
}

.tableData_hecha /deep/ .el-table tr {
  background-color: transparent !important;
}

.tableData_hecha /deep/ .el-table--enable-row-transition .el-table__body td, .el-table .cell {
  /*background-color: transparent;*/
}

.tableData_hecha /deep/ .el-table th {
  background-color: transparent;
  color: #9DB9EB;
  border: none;
}

.tableData_hecha /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(255,255,255,.1);
}

/* el-table表格去掉横线 */
.tableData_hecha /deep/ .el-table__row > td {
  border: none;
}

.tableData_hecha /deep/ .el-table {
  border-bottom: none;
}

.tableData_hecha /deep/ .el-table::before {
  background-color: transparent;
}

.pagination /deep/ .el-pagination.is-background .el-pager li {
  background-color: #212f7eb7;
  color: #81A9F1;
}

/deep/ .el-pagination.is-background .btn-next {
  background-color: #212f7eb7;
  color: #FFFFFF;
}

/deep/ .el-pagination.is-background .btn-prev:disabled {
  background-color: #212f7eb7;
  color: #FFFFFF;
}

/deep/ .el-pagination.is-background .btn-prev {
  background-color: #212f7eb7;
  color: #FFFFFF;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #30569c;
  color: #FFFFFF;
}


</style>
