<template>
  <div class="performance-container">
    <div class="chart-title">
<!--      <s-section-title-bar :showSelectArea="true" @changeArea="changeArea">整体完成情况</s-section-title-bar>-->
      <s-section-title-bar>整体完成情况</s-section-title-bar>
    </div>
    <div class="charts-content"
         v-loading="loading"
         element-loading-text="加载中..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(15,36,87,.85)">
      <div id="performanceChart" class="performanceChart"></div>
    </div>
  </div>
</template>

<script>
import {Charts} from "../../charts";
import {mapState} from "vuex";

export default {
  name: "unit-performance",
  computed:{
    ...mapState({
      loading: state => state.double.performanceChartLoading,
      performanceChart:state => state.double.performanceChart,
      // performanceAreaId:state => state.double.performanceAreaId,
      area_id:state => state.double.area_id,
    })
  },
  watch:{
    performanceChart(newVal){
      Charts.initPerformanceChart('performanceChart',newVal)
    },
    area_id(newVal){
      this.$store.dispatch('getUnitCompleteSituation',{city_id:this.area_id})
    }
  },
  mounted() {
    this.$store.dispatch('getUnitCompleteSituation',{city_id:this.area_id})
  },
  methods:{
    // changeArea(value){
    //   this.$store.commit('Performance_Area_Id',value)
    // }
  }
}
</script>

<style lang="scss" scoped>
.performance-container{

  .chart-title{
    padding-left: 20px;
    margin-bottom: 15px;
  }

  .charts-content{
    background-image: url("https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/double-bg2.png");
    background-size: 100% 100%;
    padding: 10px 20px;
  }

  .performanceChart{
    width: 100%;
    height: 220px;
  }

}
</style>
