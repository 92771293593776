<template>
  <div class="double-container">
    <div class="selected-time">
      <div>
        <s-section-title-bar :showSelectYear="true">计划年度</s-section-title-bar>
      </div>
    </div>
    <div class="statistical-chart">
      <div class="statistical-left">
        <progress-contrast />
      </div>
      <div class="statistical-right">
        <unit-performance />
      </div>
    </div>
    <div class="detailed-table">
      <detailed-statement />
    </div>
  </div>
</template>

<script>
import progressContrast from './child/progress-contrast'
import unitPerformance from './child/unit-performance'
import detailedStatement from './child/detailed-statement'
export default {
  name: "double",
  components:{
    'progress-contrast': progressContrast,
    'unit-performance': unitPerformance,
    'detailed-statement': detailedStatement
  },
  data(){
    return{
      dateValue:'',
    }
  },

  mounted() {
    this.$store.dispatch('getHasPlanYear')
    this.$store.dispatch('getHasPlanAreaList')
  },
}
</script>

<style lang="scss" scoped>
$bg-img: url("https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg3.png");
.double-container{
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 108px 20px 0 20px;
  background-image: $bg-img;
  background-size: 100% 100%;

  .selected-time{
    width: 100%;
    background-color: #172e66;
    padding: 10px 20px;
    border-radius: 6px;
  }

  .statistical-chart{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;

    .statistical-left{
      width: 70%;
    }
    .statistical-right{
      margin-left: 2%;
      width: 28%;
    }
  }

}
</style>
