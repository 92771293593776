<template>
  <div class="btn-group-content">
    <div class="btn-group">
      <div class="select">
        <el-select v-model="current" placeholder="请选择" style="width: 190px"
                   size="medium" @change="handleActive">
          <el-option
            v-for="(item,index) in list"
            :key="index"
            :label="item.name"
            :value="index"
          />
        </el-select>
      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import {Loading} from "element-ui";

export default {
  name: "btn-group",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      current: 0,
    }
  },

  computed: {
    ...mapState({
      type: state => state.electronic.type,
      org_area_id: state => state.user.org_area_id,
    }),
  },



  methods: {

    handleActive(index) {
      this.current = index;
      this.$store.commit('SwitchType', index)
    },

  },
}
</script>

<style lang="scss" scoped>
.btn-group-content {
  display: flex;
  justify-content: flex-end;
  //width: 100%;

  .btn-group {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 30px;

    &.btn-group-mini .btn-item {
      padding: 4px 10px;
    }

    &.btn-group-medium .btn-item {
      padding: 14px 18px;
    }

    &.btn-group-small .btn-item {
      padding: 4px 10px;
    }

    .btn-item {
      position: relative;
      z-index: 5;
      font-weight: 400;
      cursor: pointer;
      white-space: nowrap;
      transition: background-color .1s, color .2s;

      &.is-active {
        color: #fff;
      }

      &:active {
        background-color: #243976;
      }
    }

    .btn-active {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background-image: linear-gradient(to right, #203b87, #213e97);
      line-height: 28px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      color: $medium-font-color;
      background-color: #203b87;
      border-radius: 30px;
      border: 1px solid #2254fb;
      cursor: pointer;
      transition: left .05s;
      box-shadow: rgba(0, 0, 0, 0.1) 10px 0px 40px 0px
    }

  }

}
.select {
  ::v-deep .el-input--medium .el-input__inner{
    height: 40px;
    background-color: #203b87 !important;
    color: #FFFFFF !important;
    border: none;
  }
}
</style>
