<template>
  <div class="center-container">
    <section class="map-content">
      <map-container v-if="address !== '-'" />
    </section>
  </div>
</template>

<script>
  import map from "../components/map/map-container"
  import {mapState} from "vuex";
  export default {
    name: "center",
    components: {
      'map-container': map,
    },
    computed: {
      ...mapState({
        address: state => state.address.address,
      })
    },
  }
</script>

<style lang="scss" scoped>
  .center-container {
    position: relative;
    //z-index: 2;
    flex: 1;
    padding-top: 90px;

    .map-content {
      width: 100%;
      height: 100%;
    }
  }
</style>
