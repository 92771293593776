<template>
  <div class="supervise-container">

    <div class="left-container">
      <data-general/>
    </div>

    <div class="right-container">
      <map-container />
    </div>

  </div>
</template>

<script>
  import dataGeneral from "./child/data-general"
  import mapContainer from "./child/map-container"
  export default {
    name: "supervise",
    components: {
      dataGeneral,
      mapContainer,
    },
  }
</script>

<style lang="scss" scoped>
  $left-container-bg: url('http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg-left.png');

  .supervise-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 100px 0 0 20px;

    .left-container {
      position: relative;
      z-index: 1;
      width: 33%;
      height: 100%;
      //padding: 73px 0 0 40px;
      padding-right: 10px;
      padding-top: 8px;
      background-size: 100% auto;
      background-image: $left-container-bg;
      background-repeat: no-repeat;
    }

    .right-container {
      position: relative;
      flex: 1;
      height: 100%;
      padding-top: 10px;
    }
  }
</style>
