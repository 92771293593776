<template>
  <div class="electronic-container">

    <left-container/>

    <center-container/>

    <right-container/>

  </div>
</template>

<script>

  import right from "./right";
  import left from "./left";
  import center from "./center";
  import {mapState} from "vuex";

  export default {
    name: "electronic",
    components: {
      'right-container': right,
      'left-container': left,
      'center-container': center,
    },

    computed: {
      ...mapState({
        org_area_id: state => state.user.org_area_id,
      }),
    },

    mounted() {
      this.initData()
    },

    methods: {

      initData() {
        //电子烟主体检查概况
        this.$store.dispatch("getECShopCheckSituation");
        //获取检查平均值
        this.$store.dispatch("getECShopCheckAvgValue");
        //下级单位电子烟生产经营主体信息检查分析
        this.$store.dispatch("getSubordinateUnitCheckSituation");
        //近七天检查主体数量
        this.$store.dispatch("getNearFutureCheckShopNum");
        //下级单位电子烟生产经营主体信息检查分析
        this.$store.dispatch("getSubordinateUnitECShopNum");
        //电子烟生产经营主体类型比率
        this.$store.dispatch("getECShopTypeRatio");
        //是否有新的电子烟核查(此为轮询接口)
        this.$store.dispatch("eCShopCheckCycle");
        let params = {'limit': 5, 'page': 1, org_area_id: this.org_area_id}
        this.$store.dispatch('getTodayECShopCheckRecordList', params)
      }

    },
  }
</script>

<style lang="scss" scoped>
  .electronic-container {
    display: flex;
    width: 100%;
    height: 100%;
  }
</style>
