<template>
  <div class="routine-container">

    <left-container />

    <center-container/>

    <right-container/>

  </div>
</template>

<script>
  import left from './left'
  import center from "./center"
  import right from "./right"
  import {mapState} from "vuex";
  export default {
    name: "routine",
    components: {
      'right-container': right,
      'left-container': left,
      'center-container': center,
    },

    computed: {
      ...mapState({
        org_area_id: state => state.user.org_area_id,
      }),
    },

    watch: {
      org_area_id() {
        this.initData()
      }
    },

    mounted() {
      this.initData()
    },

    methods: {

      initData() {
        const org_area_id = this.$store.getters.org_area_id;
        const activeName = this.$store.state.electronic.activeName;
        const active_name = this.$store.state.electronic.active_name;
        //获取右侧实时检查
        this.$store.dispatch("getCheckRealTimeData", { org_area_id });
        //获取左侧区域内概况
        this.$store.dispatch("getCheckAreaInsideData", { org_area_id });
        //获取零售户检查反馈
        this.$store.dispatch("getNewExamineRecordData", { org_area_id });
        //检测右侧实时检查的数据有无更新
        this.$store.dispatch("cycleCheckRealTime");
        // this.$store.dispatch('cycleCheckAreaInside');
        //获取每日数据对比(右侧昨日数据)
        this.$store.dispatch("getDailyComparisonData", { org_area_id });
        //获取近期检查分析数据
        this.$store.dispatch("getCheckStatisticsData", {org_area_id, time_type: activeName,});
        //获取稽查人员排名数据
        this.$store.dispatch("getExaminePersonRankingData", { org_area_id });
        //获取各下级单位检查数据
        this.$store.dispatch("getCheckSubordinateUnitData", {org_area_id: this.$store.getters.parent_id || org_area_id,});
        //获取检查内容统计数据
        this.$store.dispatch("getCheckContentStatisticsData", {time_type: active_name, org_area_id});
      },

    },
  }
</script>

<style lang="scss" scoped>
  .routine-container {
    display: flex;
    width: 100%;
    height: 100%;
  }
</style>
