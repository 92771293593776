<template>
  <div class="map-page">
    <baidu-map
        class="map-container"
        :zoom="zoom"
        :center="location"
        :scroll-wheel-zoom="true"
        @ready="handleReady"
        :ak="BAI_DU_MAP_AK"
    >

      <bm-boundary
          :name="address_name"
          :strokeWeight="2"
          :fillOpacity="0.1"
          fillColor="#fff"
          strokeColor="#13c6de"
      />

      <dot-overlay
          v-for="(item,index) of list"
          :key="index"
          :color="item.color"
          :position="{lng: item.check_longitude, lat: item.check_latitude}"
          :index="index"
          :info="item"
          :type="4"
          billows="#fff"
      ></dot-overlay>

      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>

    </baidu-map>

    <div class="track-btn" @click="showTrajectory">
      <el-tooltip class="item" effect="dark" :content="show_trajectory?'已开启检查轨迹':'已关闭检查轨迹'" placement="left">
        <img v-if="show_trajectory" class="trajectory-icon" :src="$currency.ossFileURL('trajectory-active')" alt=""/>
        <img v-else class="trajectory-icon" :src="$currency.ossFileURL('trajectory-noactive')" alt=""/>
      </el-tooltip>
    </div>

    <!-- v-show="show_trajectory&&hover_trajectory_id" -->
    <div class="track-dom" v-show="show_trajectory&&hover_trajectory_id" @mouseenter="handleHover" @mouseleave="handleLeave"
         v-loading="trajectoryLoading"
         element-loading-text="加载中..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(15,36,87,.85)">
      <div :id="trackMapId"></div>
      <div class="checked-track">
        <el-select size="medium" v-model="staffer_id" placeholder="请选择检查人员"
                   ref="elSelect"
                   @visible-change="visibleChange" @change="changeStaffer">
          <el-option
              v-for="item in stafferList"
              :key="item.id"
              :label="item.staffer_trajectory"
              :value="item.id">
          </el-option>
        </el-select>
        <el-scrollbar class="scrollbar">
          <div v-if="staffCheckTrajectoryList.length===0"
               style="width: 100%;text-align: center;font-size: 14px;color: #27d1e2;margin-top: 50px">暂无检查轨迹
          </div>
          <div class="track-list" v-else>
            <div class="list-item" v-for="(item,index) of staffCheckTrajectoryList">
              <div class="left">
                <div class="dot">{{ index + 1 }}</div>
                <div class="line" v-if="index < staffCheckTrajectoryList.length-1"></div>
              </div>
              <div class="message">
                <div class="shop">
                  <div>{{ (item.created_at || ' ').split(' ')[1].substring(0, 5) }}</div>
                  <div style="max-width: 230px">{{ item.shop_name || '-' }}</div>
                  <div
                      :class="['shop-tag', `shop-tag${item.shop_status}`]">{{
                      item.shop_status_name
                    }}</div>
                </div>
                <div class="shop">
                  <div>检查地址</div>
                  <div>{{ item.check_address || '-' }}</div>
                </div>
                <div class="error" v-for="(msg,msgIndex) of item.error_message" :key="msgIndex">
                  <img class="err-icon" :src="$currency.ossFileURL('inspect-erricon')" alt=""/>
                  <div class="text">{{ msg }}</div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>

  </div>
</template>

<script>
import {BAI_DU_MAP_AK} from "@/config/config"
import BaiduMap from "@/components/vue-baidu-map/components/map/Map.vue"
import BmBoundary from "@/components/vue-baidu-map/components/others/Boundary.vue"
import dotOverlay from "@/views/home/components/map/dot-overlay.vue"
import mapStyleJSON from "../../components/map/mapStyle.json";
import {mapState} from "vuex";
import addressList from "@/views/home/components/map/cityZoom.json";
import BmNavigation from "@/components/vue-baidu-map/components/controls/Navigation";
import {debounce} from "@/utils";
import {TrackMap} from "./tMap"

export default {
  name: "map-container",
  components: {
    BaiduMap,
    BmBoundary,
    dotOverlay,
    BmNavigation,
  },
  data() {
    return {
      BAI_DU_MAP_AK,
      map: null,
      location: {lng: 0, lat: 0},
      zoom: 15,
      address_name: '陕西省',
      list: [],
      trackPath: [],
      value: '',
      shopStatusColor: [
        '停业',
        '正常营业',
        '责令停业'
      ],
      show_trajectory: true,
      // trajectoryList: [],
      stafferList: [],
      staffer_id: '',
      checked_list: [],
      handleSelectFlag: false,
      change_hover: false,
      trackMapId: 'track-map',
    }
  },

  computed: {
    ...mapState({
      address: state => state.address.address,
      checkShopList: state => state.supervise.checkShopList,
      checkStaffList: state => state.supervise.checkStaffList,
      staffCheckTrajectoryList: state => state.supervise.staffCheckTrajectoryList,
      hover_item_id: state => state.supervise.hover_item_id,
      hover_trajectory_id: state => state.supervise.hover_trajectory_id,
      // checkList: state => state.supervise.checkList,
      day_time: state => state.supervise.day_time,
      trajectoryLoading: state => state.supervise.trajectoryLoading,
    }),
  },

  watch: {

    address() {
      this.checkAddress()
    },

    checkShopList(newVal) {
      this.list = []
      for (const item of newVal) {
        const data = {...item}
        data.color = "#01cd74"
        this.list.push(data)
      }
    },

    checkStaffList(newVal) {
      this.stafferList = []
      for (const item of newVal) {
        const data = {...item}
        data.staffer_trajectory = data.realname + ' - ' + this.day_time + "检查轨迹"
        this.stafferList.push(data)
      }

      if (TrackMap.mapCtx!==null){
        TrackMap.handleReset()
      }

      setTimeout(()=>{
        if (this.stafferList.length > 0) {
          this.staffer_id = this.stafferList[0].id
          this.$store.dispatch('getStaffCheckTrajectoryList', {user_id: this.staffer_id, day_time: this.day_time})
        }else {
          this.$store.commit('staffCheckTrajectoryList',[])
          this.$store.commit('getTrajectoryLoading',false)
        }
      },500)
    },

    staffCheckTrajectoryList(newVal) {
      setTimeout(() => {
        this.createMap()
      }, 500)
    },

    hover_trajectory_id(newVal){
      if (this.show_trajectory) {
        this.$store.dispatch('getCheckStaffList', {id: this.hover_trajectory_id})
      }
    }

  },

  mounted() {
    this.checkAddress()
    if (this.show_trajectory && this.hover_trajectory_id) {
      this.initTrack()
    }
  },

  methods: {

    checkAddress() {
      this.address_name = this.address;
    },


    handleReady({map, BMap}) {
      this.map = map;
      this.location.lng = 108.748024;
      this.location.lat = 34.063161;
      this.zoom = 8;
      this.map.setMapStyleV2({
        styleId: "912c3bdb9b561674e1d1087fc122352e",
        styleJson: mapStyleJSON,
      });
    },

    // 打开轨迹
    showTrajectory() {
      this.show_trajectory = !this.show_trajectory
      this.$store.commit('setTrajectoryOpen',this.show_trajectory)
    },

    enterEvent: debounce(function (_this, bool) {
      _this.$store.commit('clearNewTimer')
    }, 400),

    handleHover() {
      this.enterEvent.call(null, this, true)
    },

    leaveEvent: debounce(function (_this, bool) {
      if (_this.handleSelectFlag) return
      _this.$store.commit('setNewTimer2')
    }, 400),

    handleLeave() {
      this.leaveEvent.call(null, this, false)
    },

    initTrack() {
      this.$store.dispatch('getCheckStaffList', {id: this.hover_trajectory_id})
      this.$store.dispatch('getStaffCheckTrajectoryList', {user_id: this.staffer_id, day_time: this.day_time})
      this.createMap()
    },
    changeStaffer(e) {
      this.handleSelectFlag = false

      if (TrackMap.mapCtx!==null){
        TrackMap.handleReset()
      }

      this.staffer_id = e
      this.$store.dispatch('getStaffCheckTrajectoryList', {user_id: this.staffer_id, day_time: this.day_time})
    },
    visibleChange(event) {
      this.handleSelectFlag = event
    },

    // 地图轨迹
    createMap() {
      if (this.staffCheckTrajectoryList.length > 0) {

        this.trackPath = this.staffCheckTrajectoryList.map(item => {
          return {lng: item.check_longitude, lat: item.check_latitude}
        })

        TrackMap.createTrackMap(this.trackMapId, this.trackPath)
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.map-page {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #133067;

  .map-container {
    width: 100%;
    height: 100%;
    background-color: #133067;
  }

  ::v-deep .anchorBL {
    display: none;
  }

  .track-btn {
    width: 45px;
    height: 45px;
    //background-color: #0abf53;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;

    .trajectory-icon {
      width: 100%;
      height: 100%;
    }
  }

  .track-dom {
    width: 90%;
    height: 600px;
    background-image: url("https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/inspect-bg.png");
    background-size: 100% 100%;
    //border: 1px solid #0abf53;
    //background-color: #274084;
    background-color: #112559;
    border-radius: 10px;
    position: absolute;
    right: 80px;
    top: 40px;
    padding: 20px;
    display: flex;
    border-radius: 20px;
    overflow: hidden;

    #track-map {
      width: 60%;
      height: 550px;
    }

    .checked-track {
      width: calc(40% - 25px);
      margin-left: 25px;

      .scrollbar {
        width: 100%;
        height: 510px;
      }

      .track-list {
        //margin-top: 18px;

        .list-item {
          //color: #333333;
          display: flex;

          .left {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-right: 10px;

            .dot {
              width: 25px;
              height: 25px;
              border-radius: 50px;
              //background-color: #eee;
              background-color: #274084;
              text-align: center;
              line-height: 25px;
            }

            .line {
              width: 3px;
              height: 100%;
              background-color: #274084;
            }
          }

          .message {
            padding: 6px 0 20px;

            .shop {
              margin-bottom: 15px;
              display: flex;

              div {
                margin-right: 10px;
              }

              .shop-tag {
                font-size: 14px;
                padding: 5px;
                //margin-left: 10px;
                border-radius: 5px;
                color: #FFFFFF;
                margin-top: -5px;
                height: 25px;
              }

              .shop-tag0 {
                background-color: #0abf53;
              }

              .shop-tag1 {
                background-color: #ff0000;
              }

              .shop-tag2 {
                background-color: #99a9bf;
              }
            }

            .error {
              display: flex;
              align-items: center;
              margin-top: 15px;

              .err-icon {
                width: 20px;
                height: 20px;
                object-fit: contain;
              }

              .text {
                font-size: 14px;
                color: #F02F45;
                margin-left: 20px;
              }
            }
          }
        }
      }

      .el-select {
        width: 100%;
        margin-bottom:10px;

        ::v-deep .el-select-dropdown {
          background-color: #274084 !important;
        }
      }

      ::v-deep .el-select--medium {
        .el-input__inner {
          border: none;
          background-color: #192e68 !important;
          //background-color: #133067 !important;
          color: #FFFFFF !important;
          font-size: 16px;
        }

        .el-select__caret {
          color: #FFFFFF !important;
        }
      }
    }
  }

}
</style>
