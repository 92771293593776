<template>
  <div class="data-container">
    <div class="top-card">
      <s-section-title-bar>数据概况</s-section-title-bar>

      <div class="selected-date">
        <div v-for="item of chooseTimeList" :key="item.id" @click="checkedDate(item.id)"
             :class="['date', {'checked-date': item.id === current}]">{{ item.name }}</div>
        <choose-time v-if="current === 3" style="width:45%;" @chooseDate="chooseOverviewDate" />
      </div>

      <div class="input-box">
        <search-input
            :value="params.shop_name"
            class="input"
            width="48.5%"
            size="small"
            placeholder="店铺名称"
            ref="search"
            :cycle_time="params.cycle_time"
            :custom_start_time='params.custom_start_time'
            :custom_end_time="params.custom_end_time"
            api="searchShopName"
            @search="onSearchShop"/>
        <search-input
            :value="params.check_staff_name"
            class="input"
            width="48.5%"
            size="small"
            :cycle_time="params.cycle_time"
            :custom_start_time='params.custom_start_time'
            :custom_end_time="params.custom_end_time"
            placeholder="稽查人员姓名"
            ref="search"
            api="searchStaffName"
            @search="onSearchName"/>
      </div>



      <div class="show-data">
        <div class="data-card">
          <countTo class="text" :startVal="startVal1" :endVal="checkOverviewData.check_staff_num"/>
          <div>提交检查人数</div>
        </div>
        <div class="data-card">
          <countTo class="text" :startVal="startVal2" :endVal="checkOverviewData.check_num"/>
          <div>提交检查次数</div>
        </div>
        <div :class="['data-card', {active: !!params.is_abnormal}]" @click="filterAbnormal">
          <countTo class="text" :startVal="startVal3" :endVal="checkOverviewData.abnormal_num"/>
          <div>疑似异常</div>
        </div>
      </div>
    </div>


    <div class="check-list"
         v-loading="checkListLoading"
         element-loading-text="加载中..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(15,36,87,.85)"
    >
      <div class="dataList-content" @scroll="handleScroll">

        <div class="list-item" v-for="item of list" :key="item.id" @mouseenter="handleHoverItem(item.id,item.created_at)" @mouseleave="handleLeave">
          <div class="shop">
            <div class="shop-name">
              <span @click="onSearchShop(item.shop_name)">{{ item.shop_name }}</span>
              <span :class="['shop-tag', `shop-tag${item.shop_status}`]">{{ item.shop_status_name }}</span>
            </div>
            <div class="time">{{ item.time_name }}</div>
          </div>
          <div class="check-message" v-for="(uItem,uIndex) of item.user_info" :key="uItem.id">
            <div class="realname" @click="onSearchName(uItem.realname)">{{ uItem.realname }} - </div>
            <div class="address">- {{ uItem.address }}</div>
          </div>
          <div class="error" v-for="(msg,msgIndex) of item.error_message" :key="msgIndex">
            <img class="err-icon" :src="$currency.ossFileURL('inspect-erricon')" alt="" />
            <div class="text">{{ msg }}</div>
          </div>
        </div>

        <div class="load-more" v-if="list.length">
          <i class="el-icon-loading" v-if="loadingStatus === 'loading'" />
          <span>{{ loadingText[loadingStatus] }}</span>
        </div>

      </div>

      <div class="empty-content"><span v-if="list.length === 0">暂无数据</span></div>
    </div>


  </div>
</template>

<script>
import {mapState} from "vuex";
import {format} from "timeago.js"
import {throttle, debounce} from "@/utils";

export default {
  name: "data-general",
  data(){
    return{
      current: -1,
      startVal1: 0,
      startVal2: 0,
      startVal3: 0,
      onceShow: true,
      loadingStatus: '',
      loadingText: {
        loading: '正在加载中...',
        loadMore: '加载更多',
        noMore: '没有更多了'
      },

      list: [],

      chooseTimeList: [
        { id: -1,name: '今日' },
        { id: 1, name: '近七天' },
        { id: 2, name: '近三十天' },
        { id: 3, name: '自定义' },
      ],

      shopStatusColor: [
        '停业',
        '正常营业',
        '责令停业'
      ],

      params: {
        page: 1,
        limit: 8,
        cycle_time: '',
        custom_start_time: '',
        custom_end_time: '',
        shop_name: '',
        check_staff_name: '',
        org_area_id: '',
        is_abnormal: '',
      },

      clickCount: 0,
      timer: null,
      timer2: null,
    }
  },

  computed: {
    ...mapState({
      checkOverviewData: state => state.supervise.checkOverviewData,
      checkList: state => state.supervise.checkList,
      checkTotal: state => state.supervise.checkTotal,
      checkListLoading: state => state.supervise.getCheckListLoading,
      checkShopList: state => state.supervise.checkShopList,
      org_area_id: state => state.user.org_area_id,
      myAuthAreaList: state => state.address.myAuthAreaList,
      address: state => state.address.address,
      trajectory_open: state => state.supervise.trajectory_open,
    }),
  },

  watch: {
    checkOverviewData(newVal, oldVal) {
      this.startVal1 = oldVal.check_num
      this.startVal2 = oldVal.check_staff_num
      this.startVal3 = oldVal.abnormal_num
    },

    checkList(newVal) {
      this.list.push(...newVal)
      this.loadingStatus = this.list.length === this.checkTotal ? 'noMore' : 'loadMore'
    },

    org_area_id(newVal) {
      this.addressChange(newVal)
    },

  },

  mounted() {
    this.params.org_area_id = this.org_area_id
    this.initData()
  },

  methods:{

    initData() {
      this.list = []
      this.params.page = 1;
      this.$store.dispatch('getCheckOverview', this.params);
      this.$store.dispatch('getCheckList', this.params);
      this.$store.dispatch('getCheckShopList', this.params);
    },

    //选择日期
    checkedDate(value){
      // 切换日期时清空已请求的数据
      if (value === this.current) return
      this.current = value
      this.params.cycle_time = value > 0 ? value : ''
      if (value !== 3) {
        this.initData()
      }
    },

    // 格式化时间
    formatTime(time) {
      if (!time) return ""
      const date = new Date(time).getTime()
      const now = Date.now()
      if ((now - date) >= 86400000 * 3) {
        return this.$currency.substring(time, 0, 16)
      }
      return format(time,'zh_CN');
    },

    // 选择自定义时间
    chooseOverviewDate(event) {
      if (event && event.length) {
        this.params.cycle_time = 3;
        this.params.custom_start_time = event[0];
        this.params.custom_end_time = event[1];
        this.initData()
      }
    },


    // 搜索
    onSearchShop(e){
      this.params.shop_name = e
      this.initData()
    },

    onSearchName(e){
      this.params.check_staff_name = e
      this.initData()
    },

    addressChange(e) {
      this.params.org_area_id = e
      this.initData()
    },

    throttleEvent: throttle(async function(target) {
      if (target.scrollTop === (target.scrollHeight - target.clientHeight)) {
        if (this.list.length < this.checkTotal) {
          this.loadingStatus = 'loading'
          this.params.page += 1;
          await this.$store.dispatch('getCheckList', this.params);
        }
      }
    }, 600),

    async handleScroll({target}) {
      this.throttleEvent.call(this, target)
    },

    enterEvent: debounce(function(_this, id,day_time) {
      clearInterval(_this.timer2)
      _this.$store.commit('clearNewTimer')
      _this.$store.commit('setHoverItemID', id)
      _this.$store.commit('setDayTime',day_time)

      _this.timer2 = setTimeout(()=>{
        _this.$store.commit('setHoverTrajectoryId',id)
      },1500)

      // clearInterval(_this.timer2)
      // _this.timer2 = setTimeout(() => {
      //   _this.$store.commit('setHoverTrajectoryId', id)
      // }, 3000)

    }, 400),

    handleHoverItem(id,created_at) {
      const day_time = created_at.split(' ')[0]
      this.enterEvent.call(null, this, id,day_time)
    },

    leaveEvent: debounce(function(_this) {
      _this.$store.commit('setNewTimer')

      clearTimeout(_this.timer2)
      if (!_this.trajectory_open){
        _this.$store.commit('setHoverTrajectoryId','')
      }
      // clearTimeout(_this.timer2)
      // _this.$store.commit('setHoverTrajectoryId', '')
      // this.timer = setTimeout(() => {
      //   _this.$store.commit('setHoverItemID', '')
      // }, 3000)

    }, 400),

    handleLeave() {
      this.leaveEvent.call(null, this)
    },

    // 筛选异常数据
    filterAbnormal() {
      this.clickEvent(this)
    },

    clickEvent: debounce(function(_this) {
      _this.clickCount ++
      const result = _this.clickCount % 2
      _this.params.is_abnormal = result ? 1 : ''
      _this.initData()
    }, 250),

  }
}
</script>

<style lang="scss" scoped>
@import "../../../../styles/mixin";
.data-container {
  .top-card {
    background-image: url("https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/inspect-bg.png");
    background-size: 100% 100%;
    padding: 20px;

    .selected-date {
      margin-top: 15px;
      display: flex;

      .date {
        height: 28px;
        font-size: 14px;
        padding: 0 10px;
        background-color: #415f9f;
        color: #81A9F1;
        line-height: 28px;
        margin-right: 10px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color .2s;
      }

      .checked-date {
        background-color: #1274a6;
        color: #FFFFFF;
      }
    }

    .input-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 70%;
      margin: 20px 0 30px 0;
    }

    .show-data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0;

      .data-card {
        width: 135px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        color: #81A9F1;
        background-image: url("https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/inspect-list-bg.png");
        background-size: 100% 100%;

        &:last-child {
          cursor: pointer;
          transition: background-color .15s;
          border-radius: 4px;

          &.active {
            background-color: rgba(39, 209, 226, .35);
          }
        }

        .text {
          font-size: 20px;
          color: #27D1E2;
          margin-bottom: 20px;
        }
      }
    }
  }

  .check-list {
    position: relative;
    margin-top: 20px;
    height: 620px;

    .dataList-content {
      height: 100%;
      overflow: auto;

      .list-item {
        background-image: url("https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/inspect-num-bg.png");
        background-size: 100% 100%;
        padding: 25px;
        margin-bottom: 10px;
        transition: background-color .2s;

        &:hover {
          background-color: rgba(29, 89, 140, .4);
        }

        .shop {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .shop-name {
            font-size: 16px;
            color: #FFFFFF;
            cursor: pointer;

            .shop-tag {
              font-size: 14px;
              padding: 3px;
              margin-left: 10px;
              border-radius: 5px;
            }

            .shop-tag0 {
              background-color: #516b60;
            }

            .shop-tag1 {
              background-color: #6b6053;
            }

            .shop-tag2 {
              background-color: #684067;
            }
          }

          .time {
            font-size: 14px;
            color: #EBAD6A;
          }
        }

        .check-message {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #FFFFFF;
          margin-top: 15px;

          .realname {
            cursor: pointer;
          }
        }

        .error {
          display: flex;
          align-items: center;
          margin-top: 15px;

          .err-icon {
            width: 20px;
            height: 20px;
            object-fit: contain;
          }

          .text {
            font-size: 14px;
            color: #F02F45;
            margin-left: 20px;
          }
        }

      }

      .load-more {
        position: relative;
        width: 100%;
        height: 40px;
        text-align: center;
        line-height: 30px;
        color: #409eff;
        span {
          margin-left: 8px;
        }
      }
    }

    .empty-content {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      @include flex();
      width: 100%;
      height: 100%;
      color: #9DB9EB;
      font-size: 14px;
    }
  }


  .pagination {
    ::v-deep .el-pagination button:disabled {
      background-color: transparent;
      color: #415F9E;
    }

    ::v-deep .el-pagination .btn-next {
      background-color: transparent;
      color: #415F9E;
    }

    ::v-deep .el-pagination .btn-next:hover {
      color: #27D1E2;
    }

    ::v-deep .el-pagination .btn-prev {
      background-color: transparent;
      color: #415F9E;
    }

    ::v-deep .el-pagination .btn-prev:hover {
      color: #27D1E2;
    }

    ::v-deep .el-pager li {
      background: transparent;
      color: #415F9E;
    }

    ::v-deep .el-pager li:hover {
      color: #27D1E2;
    }

    ::v-deep .el-pager li.active {
      color: #27D1E2;
    }
  }

  ::v-deep .el-cascader {
    margin: 0;

    .el-input {
      border-radius: 5px;
      border: 2px solid #2C84AF;
    }

    .el-input__inner {
      border-radius: 5px;
      background-color: #182b63;
      border-color: transparent;
    }
  }

  ::v-deep .el-date-editor {
    background-color: #182b63;
    border: 2px solid #2C84AF;

    .el-range-input {
      color: #81A9F1;
      background-color: #182b63;
    }

    .el-range-separator {
      padding: 0;
      color: #39d1ff;
    }
  }

  ::v-deep .el-loading-mask {

    .el-loading-spinner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      transform: translateY(-50%);
    }

    .el-icon-loading {
      position: relative;
      width: 20Px;
      height: 20Px;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 20px;
      }
    }

    .el-loading-text {
      font-weight: 500;
      font-size: 12px;
    }
  }

}

</style>
