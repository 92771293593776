window.TMap = TMap || {}

export class TrackMap {

    static mapCtx = null

    static pathList = []

    static nodeName = ''

    static center = null

    static polylineLayer = null

    static marker = null

    static marker2 = null

    static position = null

    static createTrackMap(nodeName, path= []) {
        this.initTMap(nodeName, path)
        this.createPathList(path)
        this.dotMark()
        this.initPolylineLayer()
        this.initMarker()
        setTimeout(() => {
            this.playAnimateMarker()
        }, 2000)
    }

    // 创建地图
    static initTMap(nodeName, path = []) {
        this.center = new window.TMap.LatLng(path[0].lat, path[0].lng);
        if (this.mapCtx === null) {
            this.nodeName = nodeName
            this.mapCtx = new window.TMap.Map(this.nodeName, {
                zoom: 17,
                center: this.center
            });
        } else {
            this.mapCtx.setCenter(this.center)
        }
    }


    // 生成位置坐标点信息
    static createPathList(pathArr = []) {
        this.pathList = pathArr.map(item => {
            return new window.TMap.LatLng(item.lat, item.lng)
        })
    }


    // 创建路线
    static initPolylineLayer() {

        const geometries = this.pathList.length <= 1 ? [] : [{
            id: 'erasePath',
            styleId: 'style_gray',
            paths: this.pathList,
        }]

        this.polylineLayer = new window.TMap.MultiPolyline({
            map: this.mapCtx, // 绘制到目标地图
            // 折线样式定义
            styles: {
                style_blue: new window.TMap.PolylineStyle({
                    color: '#3777FF', // 线填充色
                    width: 8, // 折线宽度
                    borderWidth: 1, // 边线宽度
                    borderColor: '#FFF', // 边线颜色
                    lineCap: 'round', // 线端头方式
                }),
                style_gray: new window.TMap.PolylineStyle({
                    color: '#ccc', // 线填充色
                    width: 8, // 折线宽度
                    borderWidth: 1, // 边线宽度
                    borderColor: '#FFF', // 边线颜色
                    lineCap: 'round', // 线端头方式
                    eraseColor: 'rgba(190,188,188,1)',
                }),
            },
            geometries: geometries,
        });
    }


    // 创建坐标点
    static initMarker() {

        const geometries = this.pathList.length <= 1 ? [] : [{
            id: 'car',
            styleId: 'car-down',
            position: this.pathList[0],
        }]

        this.marker =  new window.TMap.MultiMarker({
            map: this.mapCtx, // 绘制到目标地图
            styles: {
                'car-down': new window.TMap.MarkerStyle({
                    width: 40,
                    height: 40,
                    anchor: {
                        x: 20,
                        y: 20,
                    },
                    faceTo: 'map',
                    rotate: 180,
                    src: 'https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/moving-up.png',
                })
            },
            geometries: geometries,
        });
    }


    // 播放轨迹点回放
    static playAnimateMarker() {
        if (this.pathList.length <= 1) return

        //调用moveAlong动画 执行标记点动画开始
        this.marker.moveAlong({"car": {
                path: this.pathList,//移动路径的坐标串
                // duration: 5000,//完成移动所需的时间，单位：毫秒
                speed: 150, //speed为指定速度，正整数，单位：千米/小时
            }}, { autoRotation: true }, /*自动旋转*/);

        // 监听事件名
        this.marker.on('moving', (e) => {
            if (e.car) {
                const rotation = window.TMap.geometry.computeHeading(
                    e.car.passedLatLngs[e.car.passedLatLngs.length - 2],
                    e.car.passedLatLngs[e.car.passedLatLngs.length - 1]
                );
                this.position = window.TMap.geometry.computeDestination(this.marker.getGeometryById('car').position, rotation, 60);
            }

            this.mapCtx.easeTo(
                {
                    center: this.position,
                    rotation: e.car.angle && e.car.angle,
                    zoom: 20,
                    pitch: 70,
                },
                {
                    duration: 150,
                }
            );
            // 移动路线置灰
            this.polylineLayer.updateGeometries([
                {
                    id: 'path1',
                    styleId: 'style_blue',
                    paths: e.car && e.car.passedLatLngs,
                },
            ]);
        });

        this.marker.on('move_ended', () => {
            this.mapCtx.easeTo({
                center: this.center,
                zoom: 16,
                rotation: 0,
                pitch: 0,
            });
        });
    }


    // 设置点标记样式
    static dotMark() {
        const geometries = this.pathList.map((item,index) => {
            return {
                position: item,
                content: `${index + 1}`,
            }
        })

        this.marker2 = new TMap.MultiMarker({
            map: this.mapCtx,
            styles: {
                // 点标记样式
                default: new window.TMap.MarkerStyle({
                    width: 42, // 样式宽
                    height: 42, // 样式高
                    anchor: {x: 20, y: 40}, // 描点位置
                    src: 'https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/moving-dot.png',
                    color: '#fff', // 标注点文本颜色
                    size: 20, // 标注点文本文字大小
                    offset: {x: -1, y: -1}, // 标注点文本文字基于direction方位的偏移属性
                }),
            },
            geometries: geometries
        });
    }

    // 重置
    static handleReset() {
        this.marker.stopMove();
        this.polylineLayer.setGeometries([]);
        this.marker.setGeometries([]);
        this.marker2.setMap(null);
        this.mapCtx.easeTo({
            center: this.center,
            zoom: 16,
            rotation: 0,
            pitch: 0,
        });
    }

}
