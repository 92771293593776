<template>
  <div class="left-container">

    <div class="area-preview-data">
      <s-section-title-bar>区域内概况</s-section-title-bar>
      <preview-data-list :list="areaInsideList"/>
    </div>

    <examine-feedback />

    <content-statistics />

    <unit-examine />

  </div>
</template>

<script>
  import previewDataList from "../components/preview-data-list"
  import examineFeedback from "./child/examine-feedback"
  import contentStatistics from "./child/content-statistics"
  import unitExamine from "./child/unit-examine"
  import {mapState} from "vuex";
  export default {
    name: "left",
    components: {
      previewDataList,
      examineFeedback,
      contentStatistics,
      unitExamine,
    },
    computed: {
      ...mapState({
        areaInsideList: state => state.routine.areaInsideList,
      }),
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../styles/mixin";
  $left-container-bg: url('http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg-left.png');

  .left-container {
    position: relative;
    z-index: 2;
    width: 27.4%;
    height: 100%;
    padding: 73px 0 0 40px;
    background-size: 100% auto;
    background-image: $left-container-bg;
    background-repeat: no-repeat;

    .area-preview-data {
      width: 100%;

      .preview-data-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .preview-data-item {
          display: flex;
          align-items: center;
          width: calc(100% / 3);
          margin-top: 25px;

          .item-icon {
            display: block;
            width: 36px;
          }

          .preview-data {
            @include flexDirection();
            margin-left: 12px;

            .data-count {
              font-size: 20px;
              font-weight: normal;
              color: #FFF;
            }

            .data-name {
              @include ellipsis(1);
              font-size: 12px;
              color: #8799C0;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
  }
</style>
