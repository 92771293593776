<template>
  <bm-overlay
    :style="dotColor"
    :ref="`customOverlay${index}`"
    class="dot-marker"
    :class="{'dot-marker-animate': type === 1}"
    pane="labelPane" @draw="draw">
    <div class="dot-item" :style="{opacity}"></div>

    <div class="info-frame" v-if="type === 0">
      <p class="lice_no">许可证号：{{ info.license_no }}</p>
      <p class="check-shop-name">{{ info.shop_name }}</p>
      <p class="check-shop-address">详细地址：{{ info.shop_address }}</p>
    </div>

    <div class="info-frame" :class="calc(info.id)" v-if="type === 4">
      <p class="lice_no">许可证号：{{ info.license_no }}</p>
      <p class="check-shop-name">{{ info.shop_name }}</p>
      <p class="check-shop-address">详细地址：{{ info.shop_address }}</p>
    </div>

    <!--    <div class="info-check-frame" v-else>
          <div class="check-shop-name">西安市碑林区君汇化妆品店</div>
          <div class="lice_no">
            <hgroup class="title">许可证：</hgroup>
            <hgroup class="value">02033099494</hgroup>
          </div>
          <div class="check-shop-address">
            <hgroup class="title">地址：</hgroup>
            <hgroup class="value">西安市碑林区闪电侠路32号</hgroup>
          </div>
          <div class="check-info-item">
            <div class="main-info">
              <div class="examine-date">
                <hgroup class="title">检查时间：</hgroup>
                <hgroup class="value">2022/04/06  12:00</hgroup>
              </div>
              <div class="examine-person">
                <hgroup class="title">检查人：</hgroup>
                <hgroup class="value">小虎</hgroup>
              </div>
              <div class="examine-count">
                <hgroup class="title">检查频次：</hgroup>
                <hgroup class="value">第1000次检查</hgroup>
              </div>
            </div>
            <img class="shop-photo" :src="$currency.ossFileURL('login-out')" alt="">
          </div>
        </div>-->

  </bm-overlay>
</template>

<script>
  import BmOverlay from "@/components/vue-baidu-map/components/overlays/Overlay"
  import {mapState} from "vuex";
  export default {
    name: "dot-overlay",
    components: { BmOverlay },

    props: {
      index: {
        type: Number,
        default: ""
      },

      position: {
        type: Object,
        default: { lng: "116.397497", lat: "39.906888" }
      },

      color: {
        type: String,
        default: 'red'
      },

      info: {
        type: Object,
        default: () => ({})
      },

      billows: {
        type: String,
        default: "rgba(255, 0, 0, .25)",
      },

      type: {
        type: Number,
        default: 0,
      }
    },

    data() {
      return {
        opacity: 0,
      }
    },

    computed: {
      ...mapState({
        hover_item_id: state => state.supervise.hover_item_id
      }),

      dotColor() {
        return {
          "--dot-color": this.color,
          "--stroke-color": this.billows,
        }
      },
    },

    watch: {
      position: {
        deep: true,
        handler () {
          this.$refs[`customOverlay${this.index}`].reload()
        }
      },
    },

    mounted() {
      this.opacity = 0.6;
    },

    methods: {
      draw ({el, BMap, map}) {
        const {lng, lat} = this.position
        const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))
        el.style.left = pixel.x + 'px'
        el.style.top = pixel.y + 'px'
      },

      calc(id) {
        return {show: id === this.hover_item_id}
      },

    }

  }
</script>

<style lang="scss" scoped>
  .dot-marker {
    position: absolute;
    width: 30px;
    height: 30px;
    //background-color: rgba(0,0,0,.4);
    transform: translate(-50%, -50%);
    cursor: pointer;

    @keyframes animate {
      0% {
        width: 0;
        height: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        width: 30px;
        height: 30px;
        opacity: 0;
      }
    }

    &.dot-marker-animate::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      border-radius: 50%;
      animation: animate 2s linear infinite;
      background-color: var(--stroke-color);
      animation-delay: 2.5s;
      transform: translate(-50%, -50%);
    }

    &.dot-marker-animate::before {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      border-radius: 50%;
      background: var(--stroke-color);
      animation: animate 2s linear infinite;
      transform: translate(-50%, -50%);
      //animation-delay: calc(1);
    }

    &:hover > .info-frame {
      opacity: 1;
      visibility: inherit;
    }

    .dot-item {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      background-color: var(--dot-color);
      border-radius: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 1s;
    }

    .info-frame {
      position: absolute;
      top: 24px;
      left: 24px;
      padding: 10px 12px 8px;
      background-color: rgba(255,255,255,.15);
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      opacity: 0;
      transition: opacity .15s;
      visibility: hidden;

      &.show {
        opacity: 1 !important;
        visibility: inherit !important;
      }

      .lice_no {
        white-space: nowrap;
        width: 100%;
        font-size: 12px;
        padding-bottom: 4px;
        border-bottom: 1px solid #fff;
      }

      .check-shop-name {
        font-size: 14px;
        margin-top: 4px;
        white-space: nowrap;
      }
      .check-shop-address {
        font-size: 12px;
        margin-top: 2px;
        white-space: nowrap;
      }
    }

    .info-check-frame {
      @extend .info-frame;
      background-color: rgba(255,255,255,.25);

      .check-shop-name {
        margin-bottom: 10px;
      }

      .lice_no,
      .check-shop-address {
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 0;

        .title {
          width: 30%;
          white-space: nowrap;
        }
        .value {
          white-space: nowrap;
        }

      }

      .check-info-item {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        padding: 10px;
        border: 1px solid rgba(255, 255, 255, .5);

        .main-info {

          .examine-date,
          .examine-person,
          .examine-count {
            display: flex;
            align-items: center;
            font-size: 12px;

            & + div {
              margin-top: 8px;
            }
            .title {
              width: 70px;
              white-space: nowrap;
            }
            .value {
              white-space: nowrap;
            }
          }
        }

        .shop-photo {
          display: block;
          width: 30px;
          height: 30px;
          margin-left: 20px;
          object-fit: cover;
        }
      }

    }



  }
</style>
