<template>
  <div class="examine-feedback">
    <s-section-title-bar>零售户检查反馈</s-section-title-bar>

    <div class="examine-feedback-info">
<!--      <canvas id="examine-feedback-chart">您的浏览器暂不支持浏览该图表，请更换最新版本的浏览器!</canvas>-->
      <div class="shop-list">
        <div class="header-title">
          <span>零售户</span>
          <span>检查时间</span>
        </div>

        <div class="shop-item" v-for="(item,index) of newExamineRecordList" :key="item.id">
          <p class="shop-name" :class="[`shop-name-${['blue','orange', 'green'][index]}`]">{{ item.shop_name }}</p>
          <span class="shop-status">{{ item.created_at }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {Charts} from "@/views/home/charts";
  import {mapState} from "vuex";

  export default {
    name: "examine-feedback",
    data() {
      return {

      }
    },

    computed: {
      ...mapState({
        newExamineRecordList: state => state.routine.newExamineRecordList,
      })
    },

    methods: {

      init() {
        Charts.initDashBoardChart('examine-feedback-chart');
      }

    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../styles/mixin";
  .examine-feedback {
    width: 100%;
    margin-top: 40px;

    .examine-feedback-info {
      height:115px;//固定高度，防止刷新统计图loading位移
      display: flex;
      width: 100%;
      margin-top: 20px;

      #examine-feedback-chart {
        width: 130px;
        height: 130px;
        margin-right: 20px;
      }

      .shop-list {
        width: 100%;
        .header-title {
          display: flex;
          justify-content: space-between;
          padding: 0 40px 0 26px;
          margin-bottom: 15px;
          width: 100%;
          font-size: 14px;
          font-weight: normal;
          color: $mini-font-color;
          span:first-child {
            width: 43%;
            text-align: center;
          }
          span:last-child {
            width: 20%;
          }
        }

        .shop-item {
          display: flex;
          align-items: center;
          padding: 0 40px 0 10px;

          & + .shop-item {
            margin-top: 16px;
          }

          .shop-name {
            @include ellipsis(1);
            position: relative;
            width: 70%;
            padding-left: 16px;
            font-size: 14px;
            font-weight: normal;
            color: #FFF;

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              width: 6px;
              height: 6px;
              background-color: red;
              border-radius: 50%;
              transform: translateY(-50%);
            }

            &.shop-name-blue::after {
              background-color: #4A72F6;
            }
            &.shop-name-orange::after {
              background-color: #EC8644;
            }
            &.shop-name-green::after {
              background-color: #22C6ED;
            }
          }

          .shop-status {
            width: 30%;
            white-space: nowrap;
            font-size: 14px;
            font-weight: 400;
            color: $medium-font-color;
          }
        }
      }
    }

  }
</style>
