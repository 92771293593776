<template>
  <div class="right-container">

    <electronic-right />

    <examine-detail />

  </div>
</template>

<script>
import electronicRight from "./child/electronic_right";
import examineDetail from "./child/examine-detail";

export default {
  name: "right",
  components: {
    electronicRight,
    examineDetail,
  },
}
</script>

<style lang="scss" scoped>
$right-container-bg: url('http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg-left.png');
.right-container {
  position: relative;
  z-index: 2;
  width: 27.4%;
  height: 100%;
  padding: 73px 0 0 0;
  background-image: $right-container-bg;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .real-time-examine {
    width: 100%;
    padding: 0 19px;
  }

  .every-day-contrast {
    width: 100%;
    margin-top: 30px;
    padding: 0 19px;

    .title-bar {
      @include flex(space-between, center);
      width: 100%;

      .hint-text {
        font-weight: 400;
        font-size: 12px;
        white-space: nowrap;
        color: $medium-font-color;
      }
    }

    .three-data-list {
      height: 55px;// 固定高度
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 22px;

      .preview-data-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% / 3);

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 25%;
          height: 50%;
          border-left: 1px dashed #4c6d98;
        }

        &:nth-child(3n + 1)::before {
          content: none;
        }

        &:nth-child(n + 4) {
          margin-top: 25px;
        }

        .data-count {
          font-size: 20px;
          font-weight: normal;
          color: #FFF;
        }

        .data-name {
          @include ellipsis(1);
          margin-top: 4px;
          font-size: 12px;
          color: #8799C0;
          letter-spacing: 1px;
        }

        .preview-data {
          width: 100%;
          @include flex();
          padding-left: 8px;

          .compare-text {
            margin-top: 4px;
            font-size: 12px;
            font-weight: 400;
            color: #8799C0;
          }

          .arrow {
            display: block;
            width: 16px;
          }

        }
      }
    }
  }

  .recent-examine-analyse {
    position: relative;
    width: 100%;
    margin-top: 30px;
    padding: 0 19px;
    .options-bar {
      position: absolute;
      top: 20px;
      left: 10%;
      display: flex;
      width: 100%;
      align-items: center;
    }

    #pile-chart {
      width: 100%;
      height: 220px;
      margin-top: 60px;
    }
  }
}
</style>
