<template>
  <div class="detailed-container">
    <div class="table-title">
      <div>
<!--        <s-section-title-bar :showSelectArea="true" @changeArea="changeArea">明细表</s-section-title-bar>-->
        <s-section-title-bar>明细表</s-section-title-bar>
      </div>
    </div>
    <div class="table-container"
         v-loading="loading"
         element-loading-text="加载中..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(15,36,87,.85)">

      <div class="not-data-hint" v-if="detailedList[0] && detailedList[0].check_detail.length === 0">暂时未录入计划</div>

      <el-table class="tableData" :data="this.tableData" style="width: 100%">
        <el-table-column label="月份/已检查店铺数" prop="month" width="140">
          <template slot-scope="scope">
            {{ getMonth(scope.row.month) }}
          </template>
        </el-table-column>

        <el-table-column v-for="(areaItem,aIndex) in areaHeaderList" :key="aIndex" :label="getName(areaItem)">
          <template slot-scope="scope">
            {{ scope.row.check_detail[aIndex].actual_check_num }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: "detailed-statement",
  data(){
    return{
      tableData: [],
      areaHeaderList: [],
      options:[],
      value:'',
      selectType:'detailed'
    }
  },

  computed:{
    ...mapState({
      detailedList: state => state.double.detailedList,
      loading: state => state.double.detailedListLoading,
      area_id:state => state.double.area_id,
    })
  },

  watch: {
    detailedList(value) {
      if (value && value.length) {
        const firstDetailItem = value[0].check_detail
        this.areaHeaderList = firstDetailItem.map(item => {
          return item.org_area_name
        });
      }
      const _list = [...value]
      this.tableData = _list.reverse();
    },

    area_id(newVal){
      this.$store.dispatch('getCheckDetail',{city_id:this.area_id})
    },
  },


  mounted() {
    console.log(this.area_id)
    this.$store.dispatch('getCheckDetail',{city_id: this.area_id})
  },


  methods:{

    getMonth(month) {
      if (month===1){
        return '一月'
      }else if (month===2){
        return '二月'
      }else if (month===3){
        return '三月'
      }else if (month===4){
        return '四月'
      }else if (month===5){
        return '五月'
      }else if(month===6){
        return '六月'
      }else if (month===7){
        return '七月'
      }else if (month===8){
        return '八月'
      }else if (month===9){
        return '九月'
      }else if (month===10){
        return '十月'
      }else if (month===11){
        return '十一月'
      }else if (month===12){
        return '十二月'
      }
    },
    getName(name){
      if (name){
        return  name.replace('烟草专卖','')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.detailed-container{
  .table-title{
    padding: 0 20px 20px 20px;
    display: flex;
    align-items: center;
    .selected{
      display: flex;
      align-items: center;
      width: 20%;
      margin-left: 50px;

      ::v-deep .el-select .el-input__inner{
        background-color: transparent;
        border: none;
      }
    }
  }

  .table-container{
    position: relative;
    padding: 15px 30px;
    border-radius: 20px;
    //background-color: #172e66;
    background-image: url("https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/double-bg3.png");
    background-size: 100% 100%;

    .not-data-hint {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #81A9F1;
    }

    .tableData {
      width: 100%;
      min-height: 280px;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
      background-color: #172e66;
    }
    .el-table ::v-deep th.el-table__cell.is-leaf{
      border-bottom: 2px solid #0f2459;
    }
    ::v-deep .el-table th{
      background-color: #163f76 !important;
      color: #FFFFFF;
    }

    ::v-deep .el-table tr:nth-child(odd){
      background-color: #214181 !important;
    }
    ::v-deep .el-table tr:nth-child(even){
      background-color: #1e5191 !important;
    }
    ::v-deep .el-table tr:hover{
      background-color: rgba(14, 55, 104, 0.2) !important;
    }

    ::v-deep .el-table td.el-table__cell{
      background-color: transparent !important;
      border-bottom: none !important;
    }

    ::v-deep .el-table::before{
      background-color: transparent !important;
    }
  }
}

::v-deep .el-loading-mask {

  .el-loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    transform: translateY(-50%);
  }

  .el-icon-loading {
    position: relative;
    width: 20Px;
    height: 20Px;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 20px;
    }
  }

  .el-loading-text {
    font-weight: 500;
    font-size: 12px;
  }
}

</style>
