<template>
  <div class="unit-examine">
    <s-section-title-bar>各下级单位检查</s-section-title-bar>

    <div
      id="examine-columnar-chart"
      v-loading="loading"
      element-loading-text="数据加载中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(15,36,87,.85)"
    ></div>

  </div>
</template>

<script>
import {Charts} from "../../charts";
import {mapState} from "vuex"

export default {
  name: "unit-examine",
  data() {
    return {}
  },

  computed: {
    ...mapState({
      'subordinateUnitList': state => state.routine.subordinateUnitList,
      'loading': state => state.routine.unitExamineLoading,
    })
  },

  watch: {
    subordinateUnitList(newVal) {
      Charts.initColumnarChart('examine-columnar-chart', newVal);
    },
  },

}
</script>

<style lang="scss" scoped>
  .unit-examine {
    width: 95%;
    margin-top: 10px;

    #examine-columnar-chart {
      width: 100%;
      height: 290px;
      font-size: 14px;
      font-weight: 400;
      margin-top: 10px;
    }

  }
</style>
