<template>
  <div class="examine-detail">

    <s-section-title-bar>核查详情</s-section-title-bar>

    <div
      style="width:100%; margin-top: 30px;"
      v-loading="loading"
      element-loading-text="正在获取详情..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="#0f2457"
    >
      <div class="base-info">
        <div class="left-content">
          <img class="shop-icon" :src="$currency.ossFileURL('shop')" alt="" />
          <div class="shop-name">
            <p class="name">{{ detail.company_name || "-" }}</p>
            <p class="shop-type">{{ detail.merchant_type_name || "-" }}</p>
          </div>
        </div>

        <div class="right-content">
          <img class="card-icon" :src="$currency.ossFileURL('card')" alt="" />
          <div class="license-num">
            <p class="name">烟草许可证：</p>
            <p class="num">{{ detail.document_type === "tobacco_license" ? detail.unified_social_credit_code : "-" }}</p>
          </div>
        </div>
      </div>

      <div class="file-preview">
        <div class="title">营业执照、烟草许可证、核查表实拍</div>

        <ul class="file-list">

          <li class="file-item">
            <el-popover
              v-if="detail.business_license_img_url"
              placement="top-start"
              width="100%"
              :visible-arrow="false"
              trigger="hover">
              <img
                class="file-img-hover"
                :src="detail.business_license_img_url" alt=""/>
              <img
                class="file-img"
                slot="reference"
                title="点击查看大图"
                @click="previewImg(0)"
                :src="detail.business_license_img_url" alt=""/>
            </el-popover>
            <div v-else class="file-empty" ><i class="el-icon-picture"></i></div>
            <span class="file-title">营业执照</span>
          </li>

          <li class="file-item">
            <el-popover
              v-if="detail.tobacco_license_img_url"
              placement="top-start"
              width="100%"
              :visible-arrow="false"
              trigger="hover">
              <img class="file-img-hover"
                   :src="detail.tobacco_license_img_url" alt=""/>
              <img  class="file-img"
                    slot="reference"
                    title="点击查看大图"
                    @click="previewImg(1)"
                    :src="detail.tobacco_license_img_url" alt=""/>
            </el-popover>
            <div class="file-empty" v-else>
              <i class="el-icon-picture">
                <span class="empty-text" v-if="detail.tobacco_license_img_url === ''">暂无</span>
              </i>
            </div>
            <span class="file-title">烟草许可证</span>
          </li>

          <li class="file-item">
            <el-popover
              v-if="detail.check_form_img_url"
              placement="top-start"
              width="100%"
              :visible-arrow="false"
              trigger="hover">
              <img
                class="file-img-hover"
                :src="detail.check_form_img_url" alt=""/>
              <img
                class="file-img"
                slot="reference"
                title="点击查看大图"
                @click="previewImg(detail.tobacco_license_img_url ? 2 : 1)"
                :src="detail.check_form_img_url" alt=""/>
            </el-popover>
            <div class="file-empty" v-else><i class="el-icon-picture"></i></div>
            <span class="file-title">检查表实拍</span>
          </li>

        </ul>
      </div>

      <div class="file-preview">
        <div class="title">现状</div>
        <ul class="file-list">
          <li class="file-item">
            <el-popover
              v-if="detail.present_status_overall_appearance_img_url"
              placement="top-start"
              width="100%"
              :visible-arrow="false"
              trigger="hover">
              <img
                class="file-img-hover"
                :src="detail.present_status_overall_appearance_img_url"
                alt=""/>
              <img
                class="file-img"
                slot="reference"
                title="点击查看大图"
                @click="previewImg(detail.tobacco_license_img_url ? 3 : 2)"
                :src="detail.present_status_overall_appearance_img_url"
                alt=""/>
            </el-popover>
            <div v-else class="file-empty"><i class="el-icon-picture"></i></div>
            <span class="file-title">整体外观</span>
          </li>

          <li class="file-item">
            <el-popover
              v-if="detail.present_status_counter_img_url"
              placement="top-start"
              width="100%"
              :visible-arrow="false"
              trigger="hover">
              <img
                class="file-img-hover"
                :src="detail.present_status_counter_img_url"
                alt=""/>
              <img
                class="file-img"
                slot="reference"
                title="点击查看大图"
                @click="previewImg(detail.tobacco_license_img_url ? 4 : 3)"
                :src="detail.present_status_counter_img_url"
                alt=""/>
            </el-popover>
            <div  v-else class="file-empty"><i class="el-icon-picture"></i></div>
            <span class="file-title">柜台</span>
          </li>

          <li class="file-item">
            <el-popover
              v-if="detail.present_status_other_img_url && detail.present_status_other_img_url.length"
              placement="top-start"
              width="400"
              :visible-arrow="false"
              trigger="hover">
              <el-carousel :autoplay="false">
                <el-carousel-item v-for="(item,index) in detail.present_status_other_img_url" :key="index">
                  <img style="object-fit: contain;" width="400" class="file-img-hover" :src="item" alt=""/>
                </el-carousel-item>
              </el-carousel>
              <img class="file-img" @click="previewImg(detail.tobacco_license_img_url ? 5 : 4)" title="点击查看大图" slot="reference" :src="detail.present_status_other_img_url[0]" alt=""/>
            </el-popover>
            <div class="file-empty" v-else>
              <i class="el-icon-picture">
                <span class="empty-text" v-if="detail.present_status_other_img_url && detail.present_status_other_img_url.length === 0">暂无</span>
              </i>
            </div>
            <span class="file-title">其它</span>
          </li>
        </ul>
      </div>
    </div>

    <el-image-viewer
      v-if="previewShow"
      :initial-index="defaultIndex"
      :url-list="previewImgList"
      :on-close="closeViewer"
    />

  </div>
</template>

<script>
  import {mapState} from "vuex"
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

  export default {
    name: "examine-detail",
    components: {ElImageViewer},
    data() {
      return {
        defaultIndex: 0,
        previewShow: false,
      }
    },

    computed: {
      ...mapState({
        detail: state => state.electronic.ECExamineDetailData,
        loading: state => state.electronic.getDetailLoading,
      }),

      previewImgList() {
        const detail = this.detail || {};
        const {
          business_license_img_url, tobacco_license_img_url, check_form_img_url,
          present_status_overall_appearance_img_url, present_status_counter_img_url,
          present_status_other_img_url
        } = detail;

        return [business_license_img_url, tobacco_license_img_url, check_form_img_url,
          present_status_overall_appearance_img_url, present_status_counter_img_url,
          ...present_status_other_img_url].filter(item => !!item);
      },
    },

    methods: {

      closeViewer() {
        this.previewShow = false;
      },

      previewImg(index) {
        this.defaultIndex = index;
        this.previewShow = true;
      },

    },

  }
</script>

<style lang="scss" scoped>
  .file-img-hover {
    display: block;
    max-width: 400px;
    max-height: 400px;
    object-fit: cover;
    cursor: pointer;
  }

  .examine-detail {
    width: 100%;
    margin-top: 40px;
    padding-left: 30px;

    .base-info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 95%;
      padding: 10px 10px 14px 10px;
      margin-top: 10px;
      border: 1px solid #31436d;

      .left-content {
        display: flex;
        align-items: flex-start;

        width: 58%;
        .shop-icon {
          width: 36px;
          height: 36px;
          margin-right: 10px;
        }

        .shop-name {
          .name {
            margin-top: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #81A9F1;
          }
          .shop-type {
            margin-top: 8px;
            font-size: 14px;
            font-weight: normal;
            color: #9DB9EB;
          }
        }
      }

      .right-content {
        display: flex;
        align-items: center;
        width: 34%;

        .card-icon {
          display: block;
          width: 42px;
          height: 42px;
          margin-right: 10px;
        }

        .license-num {
          .name {
            font-size: 14px;
            color: #9DB9EB;
            margin-top: 10px;
          }
          .num {
            margin-top: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #9DB9EB;
          }
        }

      }

    }

    .file-preview {
      width: 95%;
      padding: 18px 18px 18px 18px;
      margin-top: 30px;
      border: 1px solid #31436d;

      .title {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        padding-left: 16px;
        font-size: 14px;
        font-weight: normal;
        color: #9DB9EB;
        line-height: 16px;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 100%;
          background-image: linear-gradient(to bottom, #22d3ed, #4d79ff);
        }
      }

      .file-list {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;

        .file-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;

          .file-empty {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 80px;

            .el-icon-picture {
              position: relative;
              color: #0c3866;
              .empty-text {
                position: absolute;
                top: 50%;
                left: 50%;
                white-space: nowrap;
                transform: translate(-50%, -50%);
                font-size: 12px;
                font-weight: 400;
                color: #005670;
              }
            }
          }

          .file-img {
            display: block;
            width: 80px;
            height: 80px;
            cursor: pointer;
            object-fit: cover;
          }

          .file-title {
            font-size: 14px;
            font-weight: 400;
            color: #9DB9EB;
          }

        }
      }
    }

  }

  ::v-deep .el-loading-mask {

    .el-loading-spinner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      transform: translateY(-50%);
    }

    .el-icon-loading {
      position: relative;
      width: 20Px;
      height: 20Px;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 20px;
      }
    }

    .el-loading-text {
      font-weight: 500;
      font-size: 12px;
    }
  }

</style>
