import UCharts from "@/utils/u-charts"

const echarts = require('echarts')
require('echarts/lib/component/tooltip');

export class Charts {

    static initUChart(option, id_name, sub_title) {
        option.subtitle.name = sub_title;
        const canvas = document.getElementById(id_name);
        const ctx = canvas.getContext("2d");
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        option.width = canvas.width;
        option.height = canvas.height;
        option.context = ctx;
        new UCharts(option);
    }

    static initDashBoardChart(id_name, sub_title, data = {}) {
        const option = {
            "type": "arcbar",
            "canvasId": "",
            "canvas2d": false,
            "background": "none",
            "animation": true,
            "timing": "easeOut",
            "duration": 1000,
            "color": [
                "#1890FF",
                "#91CB74",
                "#FAC858",
                "#EE6666",
                "#73C0DE",
                "#3CA272",
                "#FC8452",
                "#9A60B4",
                "#ea7ccc"
            ],
            "padding": [50, 0, 0, 0],
            "rotate": false,
            "errorReload": true,
            "fontSize": 13,
            "fontColor": "#666666",
            "enableScroll": false,
            "touchMoveLimit": 60,
            "enableMarkLine": false,
            "dataLabel": true,
            "dataPointShape": true,
            "dataPointShapeType": "solid",
            "tapLegend": true,
            "title": {
                "name": data.e_cigarette_ratio ? data.e_cigarette_ratio + "" : '0',
                "fontSize": 26,
                "color": "#FFF",
                "offsetX": 0,
                "offsetY": 0
            },
            "subtitle": {
                "name": "",
                "fontSize": 12,
                "color": "#9DB9EB",
                "offsetX": 0,
                "offsetY": 45
            },
            "extra": {
                "arcbar": {
                    "type": "default",
                    "width": 12,
                    "backgroundColor": "#2C447D",
                    "startAngle": 0.75,
                    "endAngle": 0.25,
                    "gap": 2,
                    "centerX": 0,
                    "centerY": 0,
                    "linearType": "custom",
                    "customColor": ['#22C6ED'],
                }
            },
            "series": [
                {
                    "name": sub_title,
                    "data": data.e_cigarette_ratio,
                    "color": "#9F21F7"
                }
            ]
        };
        Charts.initUChart(option, id_name, sub_title);
    }

    static initAnnularChart(id_name = '', data) {
        const chartDOM = document.getElementById(id_name);
        chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        const oneLegendHeight = Math.round((chartDOM.height / 5 / 2));
        const chart = echarts.init(chartDOM);
        /*const option = {
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: {
                orient: 'vertical',
                right: '0%',
                center: 'center',
                icon: 'circle',
                height: chartDOM.height,
                // itemGap:9,
                itemWidth:6,
                itemHeight:6,
                selectedMode:"true",
                textStyle: {
                    color: '#9DB9EB',
                    fontSize: oneLegendHeight,
                },
                data: ['双随机一公开', 'APCD检查', '许可证检查', '消费者投诉', '举报线索查办']
            },
            color: ['#972CF6', '#6D62F3', '#EC8644', '#419DEF','#22C6ED', '#749f83', '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'],
            series: [
                {
                    name: '占比统计',
                    type: 'pie',
                    radius: ['60%', '85%'],
                    center: ['40%', '50%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            formatter: '{d}% \n {b}',
                            fontSize: oneLegendHeight,
                            fontWeight: 'normal',
                            color: "#9DB9EB",
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {value: 335, name: '双随机一公开'},
                        {value: 310, name: 'APCD检查'},
                        {value: 234, name: '许可证检查'},
                        {value: 135, name: '消费者投诉'},
                        {value: 1548, name: '举报线索查办'}
                    ],
                    hoverOffset: 5
                }
            ]
        }*/

        const {
            apcd_num, cigar_num, complaint_num, e_cigarette_num, enforce_activity_num,
            field_audit_num, monitor_num, open_num, seized_case_num
        } = data;

        const seriesData = [
            apcd_num, cigar_num, complaint_num, e_cigarette_num, enforce_activity_num,
            field_audit_num, monitor_num, open_num, seized_case_num
        ];

        const yaxisData = ['APCD检查', '雪茄烟', '消费者投诉', '发现售卖电子烟次数', '联合执法活动',
            '新办许可证实地核查', '许可证后续监管', '双随机一公开', '举报线索查办'];

        const option = {
            title: {
                text: '',
                // subtext: '数据来自网络'
            },
            color: [
                '#EC8644', '#22C6ED', '#419DEF',
                '#6D62F3', '#972CF6', '#213FF7',
            ],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                show: false,
                data: [],
                right: 'right',
                top: 'center',
                orient: "vertical",
                icon: 'circle',
            },
            grid: {
                top: '0',
                left: '0',
                right: '5%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                axisLine: {
                    lineStyle: {
                        color: "#293c69",
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: "#293c69"
                    }
                },
                axisLabel: {
                    color: "#8799C0",
                }
            },
            yAxis: {
                type: 'category',
                interval: 0,
                axisLine: {
                    lineStyle: {
                        color: "#293c69",
                    }
                },
                axisLabel: {
                    fontSize: '10',
                    color: "#8799C0",
                },
                data: yaxisData
            },
            series: [
                {
                    name: '',
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                const colorList = [
                                    '#EC8644', '#22C6ED', '#419DEF', '#fb7293',
                                    '#6D62F3', '#972CF6', '#213FF7', '#ff6c5f',
                                ];
                                return colorList[params.dataIndex]
                            },
                        },
                    },
                    data: seriesData
                }
            ]
        };

        chart.setOption(option);
    }

    static initColumnarChart(id_name = '', dataList = []) {
        let chartDOM = document.getElementById(id_name);
        chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        let chart = echarts.init(chartDOM);

        if (dataList.length === 0) return;

        const xAxisList = [];
        const firstSeries = [];
        const secondSeries = [];
        dataList.forEach(item => {
            xAxisList.push(item.name);
            firstSeries.push(item.check_num);
            secondSeries.push(item.shop_num);
        })


        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            legend: {
                right: 0,
                orient: 'horizontal',
                textStyle: {
                    fontSize: 14,
                    color: "#8799C0"
                },
                // icon: 'roundRect',
                data: ['检查数', '零售户数']
            },
            color: ['#22C6ED', '#d3783d', '#EC8644', '#419DEF', '#22C6ED', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'],

            xAxis: [
                {
                    type: "category",
                    right: 0,
                    data: xAxisList,
                    axisPointer: {
                        show: true,
                        type: 'shadow',
                    },
                    axisLabel: {
                        rotate: 30,
                        color: "#8799C0",
                        textStyle: {
                            color: '#9DB9EB',
                            fontSize: '10'
                        },
                    }
                }
            ],
            grid: {
                top: '15%',
                bottom: '11%'
            },
            yAxis: [
                {
                    type: 'value',
                    name: '',
                    axisLabel: {
                        // formatter: '{value} ml',
                        textStyle: {
                            color: '#9DB9EB'
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#293c69"
                        },
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: "#293c69"
                        }
                    },
                    axisPointer: {
                        show: false,
                    }

                },
                {
                    type: 'value',
                    name: '',
                    axisLabel: {
                        // formatter: '{value} °C',
                        textStyle: {
                            color: '#9DB9EB'
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#293c69"
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#293c69"
                        }
                    },
                    axisPointer: {
                        show: false,
                    }
                }
            ],
            series: [
                {
                    name: '检查数',
                    type: 'bar',
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            2, 0, 0, 1,
                            [
                                {offset: 0, color: '#22C6ED'},
                                {offset: 1, color: '#213FF7'}
                            ]
                        )
                    },
                    data: firstSeries
                },
                {
                    name: '零售户数',
                    type: 'line',
                    yAxisIndex: 1,
                    data: secondSeries
                }
            ]
        };

        chart.setOption(option);
    }

    static initPileChart(id_name = '', type = 0, dataList = []) {
        const chartDOM = document.getElementById(id_name);

        if (chartDOM != null) {
            chartDOM.width = chartDOM.clientWidth;
            chartDOM.height = chartDOM.clientHeight;
            const chart = echarts.init(chartDOM);

            const xAxisList = [], yAxisList1 = [], yAxisList2 = [];

            function formatXAxis(str) {
                return type === 0 ? `${str}:00` : str
            }

            dataList.forEach(item => {
                xAxisList.push(formatXAxis(item.day_time));
                yAxisList1.push(item.check_num);
                yAxisList2.push(item.check_shop_num);
            })
            const option = {
                title: {
                    text: '',
                    padding: [0, 0, 0, 18],
                    textStyle: {
                        fontSize: 14,
                        color: "#8799C0"
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    show: true,
                    data: ['检查数量', '检查店铺数量'],
                    top: '0%',
                    center: 'right',
                    textStyle: {
                        fontSize: 14,
                        color: "#8799C0"
                    },
                },
                color: ['#F4BB2D', '#448BE9',],
                grid: {
                    top: "18%",
                    left: '3%',
                    right: '4%',
                    bottom: '0',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        axisPointer: {
                            show: true,
                            type: 'shadow'
                        },
                        axisLabel: {
                            color: "#8799C0",
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#293c69",
                            }
                        },
                        data: xAxisList,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisPointer: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#293c69",
                            }
                        },
                        axisLabel: {
                            color: "#8799C0",
                        },

                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "#293c69"
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '检查数量',
                        type: 'line',
                        smooth: true,
                        data: yAxisList1,
                    },
                    {
                        name: '检查店铺数量',
                        type: 'line',
                        smooth: false,
                        data: yAxisList2,
                    }
                ]
            };
            chart.setOption(option);
        }
    }

    static initelectronicchart(id_name = '', dataList = []) {
        const chartDOM = document.getElementById(id_name);
        if (chartDOM != null) {
            chartDOM.width = chartDOM.clientWidth;
            chartDOM.height = chartDOM.clientHeight;
            const chart = echarts.init(chartDOM);
            let nameList = []
            let check_numList = []
            let not_check_numList = []
            dataList.forEach((item, index) => {
                nameList.push(item.name)
                check_numList.push(item.check_num)
                not_check_numList.push(item.not_check_num)
            })

            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    right: 30,
                    textStyle: {
                        fontSize: 14,
                        color: "#9DB9EB"
                    },
                },
                color: ['#615ADF', '#35A7EA',],
                grid: {
                    top: '9%',
                    left: '0%',
                    right: '8%',
                    bottom: '8%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    axisLabel: {
                        color: "#8799C0",
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#293c69",
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#293c69"
                        }
                    },
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    axisLine: {
                        lineStyle: {
                            color: "#293c69",
                        }
                    },
                    axisLabel: {
                        color: "#8799C0",
                    },
                    // splitLine: {
                    //     show: true,
                    //     lineStyle: {
                    //         color: "#293c69"
                    //     }
                    // },
                    data: nameList
                },
                series: [
                    {
                        name: '待核查',
                        type: 'bar',
                        data: not_check_numList
                    },
                    {
                        name: '已核查',
                        type: 'bar',
                        data: check_numList
                    }
                ]
            };

            chart.setOption(option);
        }
    }


    static initmanagementTypeChar(id_name = '', dataList = []) {
        const chartDOM = document.getElementById(id_name);
        chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        const chart = echarts.init(chartDOM);
        let nameDate = [{value: dataList.enterprise_num, name: '企业', ratio: dataList.enterprise_ratio},
            {value: dataList.retail_num, name: '零售户', ratio: dataList.retail_ratio},]
        const option = {
            // title: {
            //     text: '主题类型对比',
            //     left: '30%',
            //     textStyle:{
            //         color: '#00AEFF',
            //         fontSize: 15
            //     }
            // },
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                top: '65%',
                right: '8%',
                width: 100,
                textStyle: {
                    fontSize: 14,
                    color: "#9DB9EB"
                },
            },
            color: ['#615ADF', '#26A5E4'],
            graphic: [{
                type: 'text',
                left: '33%',
                top: '48%',
                z: 10,
                // style: {
                //     fill: '#FFFFFF',
                //     text: '电子烟',
                //     font: '16px Microsoft YaHei'
                // }
            }],
            series: [
                {
                    type: 'pie',
                    center: ['38%', '55%'],
                    radius: ['35%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        formatter(params) {
                            return params.data.ratio * 100 + "%"
                        },
                    },
                    data: nameDate
                }
            ]
        };
        chart.setOption(option);
    }


    static initmanagementCityChar(id_name = '', dataList = []) {
        const chartDOM = document.getElementById(id_name);
        chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        const chart = echarts.init(chartDOM);

        let nameList = []
        let shop_numList = []
        dataList.forEach((item, index) => {
            nameList.push(item.name)
            shop_numList.push(item.shop_num)
        })

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                left: '0%',
                right: '5%',
                bottom: '5%',
                top: '8%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    axisPointer: {
                        show: true,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#293c69",

                        }
                    },
                    axisLabel: {
                        rotate: 30,
                        color: "#8799C0",
                        textStyle: {
                            fontSize: '12'
                        },
                    },
                    data: nameList,
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisPointer: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#293c69",
                        }
                    },
                    axisLabel: {
                        color: "#8799C0",
                    },

                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#293c69"
                        }
                    }
                }
            ],
            series: [
                {
                    type: 'bar',
                    barWidth: '60%',
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {offset: 0, color: '#22C6ED'},
                            {offset: 1, color: '#213FF7'}
                        ]),
                        barBorderRadius: [50, 50, 0, 0]
                    },
                    data: shop_numList
                }
            ]
        };
        chart.setOption(option);
    }

    // 计划进度对比
    static initProgressChart(id_name = '', dataList = []) {
        const chartDOM = document.getElementById(id_name);
        chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        const chart = echarts.init(chartDOM);

        let nameData = []
        let progressNum = []
        let targetNum = []

        dataList.forEach(item=>{
            nameData.push((item.org_area_name).replace('烟草专卖',''))
            progressNum.push((item.plan_extraction_shop_num))
            targetNum.push(item.actual_check_num)
        })

        const options = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {

                right: '15%',
                textStyle: {
                    fontSize: 14,
                    color: "#9DB9EB"
                },
            },
            color: ['#009FE3', '#FFBA58'],
            grid: {
                top: '15%',
                left: '0%',
                right: '8%',
                bottom: '8%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                axisLine: {
                    lineStyle: {
                        color: "#293c69",
                    }
                },
                axisLabel: {
                    color: "#8799C0",
                },
                data: nameData,
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: "#8799C0",
                },
                axisLine: {
                    lineStyle: {
                        color: "#293c69",
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: "#293c69"
                    }
                },
                boundaryGap: [0, 0.01]
            },
            series: [
                {
                    name: '计划目标-检查店铺数量',
                    type: 'bar',
                    barWidth: '20%',
                    label: {
                        show: true,
                        position: 'top',
                        color: '#06DDFD'
                    },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {offset: 0, color: 'rgba(0, 159, 227, 1)'},
                            {offset: 1, color: 'rgba(0, 160, 228, 0)'}
                        ])
                    },
                    data: progressNum
                },
                {
                    name: '当前完成-检查店铺数量',
                    type: 'bar',
                    barWidth: '20%',
                    label: {
                        show: true,
                        position: 'top',
                        color: '#06DDFD'
                    },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {offset: 0, color: 'rgba(255, 186, 88, 1)'},
                            {offset: 1, color: 'rgba(250, 185, 93, 0)'}
                        ])
                    },
                    data: targetNum
                }
            ]
        };

        chart.setOption(options);
    }

    // 各单位完成情况
    static initPerformanceChart(id_name = '', data = []) {
        // console.log(data)
        const chartDOM = document.getElementById(id_name);
        chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        const chart = echarts.init(chartDOM);

        const options = {
            tooltip: {
                trigger: 'item',
                formatter: '{b} {d}%'
            },
            legend: {
                show: true,
                orient: 'vertical',
                bottom: '10%',
                right: 0,
                itemWidth: 12,
                itemHeight: 12,
                textStyle: {
                    fontSize: 14,
                    color: "#9DB9EB"
                },
            },
            label: {
                formatter: '{d}%',
                color: '#8799C0',
            },
            labelLine: {
                lineStyle: {
                    color: '#06D8FF',
                }
            },
            color: ['#0e86b4', '#714ce8'],
            series: [
                {
                    type: 'pie',
                    radius: '50%',
                    data: [
                        {value: data.finish_num, name: '已完成检查店铺数量'},
                        {value: data.unfinished_num, name: '未完成检查店铺数量'},
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        },
                    }
                }
            ]
        }

        chart.setOption(options)
    }
}
