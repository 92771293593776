<template>
  <ul class="preview-data-list">
    <li class="preview-data-item" v-for="(item,index) of list" :key="index">
      <img class="item-icon" :src="$currency.ossFileURL(item.icon)" alt="" />
      <div class="preview-data">
        <countTo class="data-count" :startVal="item.old_count" :endVal="item.count" />
        <span class="data-name">{{ item.name }}</span>
      </div>
    </li>
  </ul>
</template>

<script>

export default {
  name: "preview-data-list",
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  watch: {

  },
}
</script>

<style lang="scss" scoped>
  @import "../../../styles/mixin";

  .preview-data-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 22px;

    .preview-data-item {
      display: flex;
      align-items: center;
      width: calc(100% / 3);

      &:nth-child(n + 4) {
        margin-top: 25px;
      }

      .item-icon {
        display: block;
        width: 36px;
      }

      .preview-data {
        margin-left: 12px;
        .data-count {
          font-size: 20px;
          font-weight: normal;
          color: #FFF;
        }
        .data-name {
          @include ellipsis(1);
          margin-top: 4px;
          font-size: 12px;
          color: #8799C0;
          letter-spacing: 1px;
        }
      }
    }
  }
</style>
