<template>
  <dv-full-screen-container class="home">
    <section class="home-container">

      <bi-header/>

      <!-- 常规检查 -->
      <routine-examine v-if="type == 0" />

      <!-- 电子烟 -->
      <e-cigarette v-else-if="type == 1" />

      <!-- 双随机一公开 -->
      <double-random-public v-else-if="type == 2" />

      <!-- 稽查监管大数据 -->
      <check-supervise-data v-else-if="type == 3" />



    </section>
  </dv-full-screen-container>
</template>

<script>

  import BiHeader from "./components/bi-header"
  import routineExamine from "./routine/routine"
  import eCigarette from "./electronic/electronic"
  import doubleRandomPublic from "./double/double"
  import checkSuperviseData from "./supervise/supervise"
  import {mapState} from "vuex";

  export default {
    name: "home",
    components: {
      BiHeader,
      routineExamine,
      eCigarette,
      doubleRandomPublic,
      checkSuperviseData,
    },

    data() {
      return {
        timer: null,
      };
    },

    computed: {
    ...mapState({
        type: state => state.electronic.type
      }),
    },


    mounted() {
      // 每隔10秒请求
      this.timer = setInterval(() => {
        if (this.type === 0) {
          this.$store.dispatch("cycleCheckRealTime");
        } else {
          this.$store.dispatch("eCShopCheckCycle");
        }
      }, 10000);
    },

    beforeDestroy() {
      clearInterval(this.timer);
      this.timer = null;
    },
  }
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";

$bg-img1: url("http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg2.png");
$bg-img2: url("http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg.png");

.home {
  width: 100%;
  height: 100%;
  //height: 9 / 16 * 100vw;
  padding: 20px;
  margin: 0 auto;
  background-image: $bg-img1, $bg-img2;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;

  .home-container {
    //@include flex(space-between, center);
    position: relative;
    width: 100%;
    height: 100%;
    color: #fff;
    border: 1px solid #216d9e;
  }
}
</style>
