<template>
  <div class="right-container">

    <div class="real-time-examine">
      <s-section-title-bar>实时检查（今日内）</s-section-title-bar>

      <preview-data-list :list="realTimeExamineList" />
    </div>

    <div class="every-day-contrast">
      <div class="title-bar">
        <s-section-title-bar>昨日数据</s-section-title-bar>
        <span class="hint-text">{{ `数据更新于${update_date}` }}</span>
      </div>
      <ul class="three-data-list">
        <li class="preview-data-item" v-for="item of dailyComparisonList" :key="item.id">
          <countTo class="data-count" :startVal="item.last_num" :endVal="item.num" />
          <span class="data-name">{{ item.name }}</span>
          <div class="preview-data">
            <span class="compare-text">{{ `较昨日${item.ratio}` }}</span>
            <img v-if="item.color === 'green'" class="arrow" :src="$currency.ossFileURL('arrow-down')" alt="" />
            <img v-else-if="item.color === 'red'" class="arrow" :src="$currency.ossFileURL('arrow-up')" alt="" />
          </div>
        </li>
      </ul>
    </div>

    <div class="recent-examine-analyse">
      <s-section-title-bar>近期检查分析</s-section-title-bar>

      <div class="options-bar">
        <el-tabs v-model="activeName" @tab-click="handleChange">
          <el-tab-pane label="今天"   name="0"/>
          <el-tab-pane label="近7天"  name="1"/>
          <el-tab-pane label="近30天" name="2"/>
          <el-tab-pane label="近90天" name="3"/>
        </el-tabs>
      </div>
      <div
        id="pile-chart"
        class="pile-chart"
        v-loading="loading"
        element-loading-text="数据加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(15,36,87,.85)"
      ></div>
    </div>

    <person-ranking />

  </div>
</template>

<script>
import previewDataList from "../components/preview-data-list";
import {mapState} from "vuex";
import {Charts} from "../../home/charts";
import personRanking from "./child/person-ranking";

export default {
  name: "right",
  components: {
    previewDataList,
    personRanking,
  },

  data() {
    return {
      activeName: '0',
    }
  },

  computed: {
    ...mapState({
      update_date: state => state.update_date,
      loading: state => state.routine.analyseLoading,
      realTimeExamineList: state => state.routine.realTimeExamineList,
      dailyComparisonList: state => state.routine.dailyComparisonList,
      checkStatisticsList: state => state.routine.checkStatisticsList,
    }),
  },

  watch: {
    checkStatisticsList(newVal) {
      Charts.initPileChart('pile-chart', +this.activeName, newVal);
    },
  },

  methods: {

    handleChange() {
      this.$store.commit('getActiveName',this.activeName)
      const params = {time_type: +this.activeName};
      this.$store.dispatch('getCheckStatisticsData', params);
    },

  },
}
</script>

<style lang="scss" scoped>
  $right-container-bg: url('http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg-left.png');
  .right-container {
    position: relative;
    z-index: 2;
    width: 27.4%;
    height: 100%;
    padding: 80px 0 0 0;
    background-image: $right-container-bg;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .real-time-examine {
      width: 100%;
      padding: 0 19px;
    }

    .every-day-contrast {
      width: 100%;
      margin-top: 30px;
      padding: 0 19px;

      .title-bar {
        @include flex(space-between, center);
        width: 100%;

        .hint-text {
          font-weight: 400;
          font-size: 12px;
          white-space: nowrap;
          color: $medium-font-color;
        }
      }

      .three-data-list {
        height: 55px;// 固定高度
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 22px;

        .preview-data-item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: calc(100% / 3);

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 25%;
            height: 50%;
            border-left: 1px dashed #4c6d98;
          }

          &:nth-child(3n + 1)::before {
            content: none;
          }

          &:nth-child(n + 4) {
            margin-top: 25px;
          }

          .data-count {
            font-size: 20px;
            font-weight: normal;
            color: #FFF;
          }

          .data-name {
            @include ellipsis(1);
            margin-top: 4px;
            font-size: 12px;
            color: #8799C0;
            letter-spacing: 1px;
          }

          .preview-data {
            width: 100%;
            @include flex();
            padding-left: 8px;

            .compare-text {
              margin-top: 4px;
              font-size: 12px;
              font-weight: 400;
              color: #8799C0;
            }

            .arrow {
              display: block;
              width: 16px;
            }

          }
        }
      }
    }

    .recent-examine-analyse {
      position: relative;
      width: 100%;
      margin-top: 30px;
      padding: 0 19px;
      .options-bar {
        position: absolute;
        top: 20px;
        left: 10%;
        display: flex;
        width: 100%;
        align-items: center;
      }

      #pile-chart {
        width: 100%;
        height: 220px;
        margin-top: 60px;
      }
    }
  }
</style>
