<template>
  <div class="progress-container">
    <div class="chart-title">
<!--      <s-section-title-bar :showSelectArea="true" @changeArea="changeArea">计划进度对比</s-section-title-bar>-->
      <s-section-title-bar>计划进度对比</s-section-title-bar>
    </div>
    <div class="charts-content"
         v-loading="loading"
         element-loading-text="加载中..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(15,36,87,.85)">
      <div id="progressChart" class="progressChart"></div>
      <div v-if="progressChart.length === 0" class="not-data-hint">暂时未录入计划</div>
    </div>
  </div>
</template>

<script>
import {Charts} from "../../charts";
import {mapState} from "vuex"

export default {
  name: "progress-contrast",
  data(){
    return{
      selectType:'progress'
    }
  },
  computed:{
    ...mapState({
      loading: state => state.double.progressChartLoading,
      progressChart: state => state.double.progressChart,
      // progressAreaId: state => state.double.progressAreaId,
      area_id:state => state.double.area_id,
    })
  },
  watch: {
    progressChart(newVal){
      Charts.initProgressChart('progressChart',newVal)
    },
    area_id(newVal){
      this.$store.dispatch('getPlanProgressSpeedContrast',{city_id:this.area_id})
    },
  },

  mounted() {
    this.$store.dispatch('getPlanProgressSpeedContrast',{city_id:this.area_id})
  },
  methods:{
    // changeArea(value){
    //   this.$store.commit('Progress_Area_Id',value)
    // }
  }
}
</script>

<style lang="scss" scoped>
.progress-container{

  .chart-title{
    padding-left: 20px;
    margin-bottom: 15px;
  }

  .charts-content{
    position: relative;
    background-image: url("https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/double-bg1.png");
    background-size: 100% 100%;
    padding: 10px 20px;

    .not-data-hint {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #81A9F1;
    }
  }

  .progressChart{
    width: 100%;
    height: 220px;
  }
}
</style>
