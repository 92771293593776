<template>
  <header class="bi-header">

    <div class="left-content">
      <div class="current-time content-item">
        <time class="time-num">{{ currentTime }}</time>
        <time class="date-num">{{ currentDate }}</time>
      </div>

      <div class="weather-info content-item">
        <img class="weather-icon" :src="$currency.ossFileURL(`${weather.wea_img?weather.wea_img:'logo'}`)" alt=""/>
        <div class="weather-temperature">
          <span class="temperature-num">{{weather.tem?weather.tem+"°":""}}</span>
          <span class="weather-name">{{weather.wea}}</span>
        </div>
      </div>
      <div class="address-info content-item">
        <div class="dropdown-info">
          <div class="province-city">{{ address }}</div>
          <img class="handle-icon" :src="$currency.ossFileURL('switchover')" alt=""/>
        </div>
        <el-cascader
          ref="myCascader"
          :props="{ checkStrictly: true, expandTrigger: 'hover', disabled:'disabled' }"
          :options="cityAreaList"
          @change="addressChange"
        />
      </div>
    </div>

    <main class="center-content">
      <div class="bi-title">
        <h1 class="title" v-if="type == 0">市场采集助手数据大屏</h1>
        <h1 class="title" v-if="type == 1">电子烟态势感知</h1>
        <h1 class="title" v-if="type == 2">陕西省烟草专卖局数据大屏</h1>
        <h1 class="title" v-if="type == 3">陕西省烟草专卖局数据大屏</h1>
      </div>

      <article class="main-data" v-if="type==0">
        <div class="data-item">
          <countTo
            class="item-count"
            :startVal="startNum1"
            :endVal="all_check_num"
            :duration="1500" />
          <span class="item-name">总检查次数</span>
        </div>
        <div class="data-item">
          <countTo class="item-count" :startVal="startNum2" :endVal="hold_cert_shop_num" />
          <span class="item-name">持烟草许可证零售户数</span>
        </div>
        <div class="data-item">
          <countTo class="item-count" :startVal="startNum3" :endVal="total_check_num" />
          <span class="item-name">今日检查数</span>
        </div>
      </article>
    </main>

    <div class="right-content">
      <btn-group size="medium" :list="moduleList" />
      <el-popconfirm
        confirm-button-text='确认'
        cancel-button-text='取消'
        confirm-button-type="text"
        @confirm="handleLoginOut"
        icon="el-icon-info"
        icon-color="#f90"
        title="是否要退出登录？">
        <img
          slot="reference"
          class="about-icon"
          :src="$currency.ossFileURL('login-out')"
          alt="" />
      </el-popconfirm>
      <div class="user-info">
        <div class="user-info-content">
          <img class="avatar" :src="$currency.ossFileURL('user')" alt="" />
          <div class="info">
            <span class="username">{{ username }}</span>
            <span class="role">{{ admin_name }}</span>
          </div>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
  import {mapState} from "vuex";
  import btnGroup from "./btn-group"

  export default {
    name: "top-header",
    components: {
      btnGroup,
    },

    data() {
      return {
        timer: null,
        startNum1: 0,
        startNum2: 0,
        startNum3: 0,
        moduleList: [
          {name: '常规检查'},
          {name: '电子烟'},
          {name: '双随机一公开全年计划'},
          {name: '稽查监管大数据'},
        ],
        cityAreaList: []
      }
    },

    computed: {
      ...mapState({
        currentDate: state => state.current_date,
        currentTime: state => state.current_time,
        org_area_id: state => state.user.org_area_id,
        cityList: state => state.address.cityList,
        myAuthAreaList: state => state.address.myAuthAreaList,
        address: state => state.address.address,
        weather:  state => state.weather_info,
        type: state => state.electronic.type,
        username:  state => state.user.username,
        admin_name:  state => state.user.admin_name,
        all_check_num: state => state.routine.centerTopData.all_check_num,
        total_check_num: state => state.routine.centerTopData.total_check_num,
        hold_cert_shop_num: state => state.routine.centerTopData.hold_cert_shop_num,
      }),
    },

    watch: {
      all_check_num(newVal, oldVal) {
        this.startNum1 = oldVal;
      },

      hold_cert_shop_num(newVal, oldVal) {
        this.startNum2 = oldVal;
      },

      total_check_num(newVal, oldVal) {
        this.startNum3 = oldVal;
      },

      type(newVal){
        console.log(this.cityList)
        this.cityAreaList = newVal === 2 ? this.cityList : this.myAuthAreaList
      },
    },

    mounted() {
      this.initData()
    },

    methods: {

      initData() {
        this.getUserInfo()
        this.getCurrentTime()
        this.getCityWeather()
        this.getShaanxiCity()
      },

      // 获取账户信息
      getUserInfo() {
        this.$store.dispatch("getUserInfo");
      },


      // 实时获取当前的年月日时分秒
      getCurrentTime() {
        this.timer = setInterval(() => {
          this.$store.commit("GET_CURRENT_TIME");
        }, 1000);
      },


      // 获取当前城市的天气信息
      getCityWeather() {
        this.$store.dispatch('GET_WEATHER_INFO', '西安');
      },


      // 获取陕西省所有城市信息
      async getShaanxiCity() {
        await this.$store.dispatch("getAreaList");
        this.$store.commit("extractMyAuthCityList", this.$store.getters.org_area_id);
        this.$store.commit('saveCityList', this.$store.getters.org_area_id)
        this.cityAreaList = this.myAuthAreaList
      },


      // 切换城市
      addressChange(e) {
        const id = e[e.length-1];
        const nameList = this.$refs.myCascader.getCheckedNodes()[0].pathLabels;
        let name = nameList.length === 3 ? `${nameList[1]}${nameList[2]}` : nameList.join("");
        // if (nameList[0] === "陕西省") name = `陕西省`;
        this.$store.commit('editCityAreaName', name);
        this.$store.commit('setParentId', e.length > 1 ? e[e.length-2] : 610000);
        this.$store.commit('setOrgAreaId', id);
        this.$store.commit('Area_Id',e[1] || 610100)
        this.$refs.myCascader.dropDownVisible = false

        let placeName = nameList.slice(-1).join().substring(0,nameList.slice(-1).join().length-1)
        if(placeName==='陕西'){
          placeName = '西安'
        }else if(placeName==='市辖'){
          placeName = '西安'
        }

        // this.$store.dispatch('GET_WEATHER_INFO',placeName)

      },


      // 退出登录
      async handleLoginOut() {
        const {level, message} = await this.$api.loginOut();
        if (level === 'success') {
          this.$notify.success(message);
          this.$store.commit('SwitchType', 0)
          this.$store.commit('clearMyAuthCityList');
          this.$store.commit('saveUserToken', "");
        }
      },

    },


    // 页面销毁清空延时器
    beforeDestroy() {
      clearInterval(this.timer);
      this.timer = null;
    },


  }
</script>

<style lang="scss" scoped>
  @import "../../../styles/mixin";
  $header-title-bg: url('http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/header-title-bg.png');
  $bg-img1: url("http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg2.png");
  $bg-img2: url("http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg.png");
  $left-container-bg: url('http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg-left.png');
  $right-container-bg: url('http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg-left.png');

  .bi-header {
    position: absolute;
    top: 0;
    left: 0;
    @include flex(space-between, flex-start);
    width: 100%;
    height: 73px;

    .left-content {
      position: relative;
      z-index: 3;
      display: flex;
      align-items: center;
      width: 27.4%;
      height: 68px;
      margin-bottom: 15px;
      padding-left: 40px;

      .content-item {
        position: relative;

        & + .content-item::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 20px;
          background-color: #4C6D98;
          transform: translateY(-50%);
        }
      }

      .current-time {
        @include flexDirection();
        align-items: flex-start;
        width: 20%;

        .time-num {
          font-weight: 400;
          font-size: 20px;
          color: #FFFFFF;
        }

        .date-num {
          margin-top: 4px;
          font-weight: 400;
          font-size: 12px;
          color: #FFFFFF;
        }
      }

      .weather-info {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;

        .weather-icon {
          display: block;
          width: 32px;
        }

        .weather-temperature {
          display: flex;
          align-items: flex-end;
          margin-left: 10px;
          color: #FFF;

          .temperature-num {
            font-weight: 400;
            font-size: 24px;
            height: 22px;
            line-height: 22px;
          }

          .weather-name {
            font-size: 12px;
          }
        }
      }

      .address-info {
        position: relative;
        width: 50%;
        cursor: pointer;

        .dropdown-info {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        .province-city {
          @include ellipsis(1);
          font-size: 24px;
          font-weight: 500;
          color: #FFFFFF;
          letter-spacing: 1px;
        }

        .handle-icon {
          width: 30px;
        }
      }

    }

    .center-content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //flex: 1;
      width: 100%;
      height: 108px;


      .bi-title {
        width: 100%;
        background-image:  $bg-img1;
        background-size: 100%;
        background-position: center top;


        .title {
          width: 636px;
          height: 81px;
          margin: 0 auto 27px;
          background-image: $header-title-bg;
          background-repeat: no-repeat;
          background-size: 100% auto;
          font-size: 35px;
          text-align: center;
          line-height: 81px;
          letter-spacing: 2px;
          @include ellipsis(1);
        }
      }

      .main-data {
        position: absolute;
        top: 100%;
        left: 0;
        @include flex(center, flex-start);
        width: 100%;
        background-image:  $bg-img1;
        background-size: 100% auto;
        background-position: center -108px;
        //height: 132px;
        //margin-top: 5px;

        .data-item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 3px;
          width: 218px;
          height: 90px;

          &:nth-child(1n + 4) {
            margin-top: 10px;
          }

          & + .data-item::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 1px;
            height: 30px;
            background-color: #4C6D98;
            transform: translateY(-50%);
          }

          .item-count {
            font-size: 24px;
            color: #fff;
            font-weight: 500;
          }

          .item-name {
            @include ellipsis(1);
            margin-top: 4px;
            font-size: 14px;
            color: #8799C0;
            letter-spacing: 1px;
          }
          .item-nameType {
            @include ellipsis(1);
            font-size: 20px;
            color: #0FFFFF;
            letter-spacing: 1px;
          }

        }
      }
    }

    .right-content {
      position: relative;
      z-index: 3;
      display: flex;
      width: 27.4%;
      align-items: center;
      padding: 15px 0 0 30px;


      .about-icon {
        display: block;
        width: 48px;
        margin: 0 25px;
        cursor: pointer;
        transition: opacity .1s;

        &:hover {
          opacity: .70;
        }
      }

      .user-info {
        flex: 1;
        position: relative;
        @include flex(center, flex-start);

        &::after {
          content: "";
          position: absolute;
          top: 12%;
          left: 0;
          height: 80%;
          width: 1px;
          border-left: 1px dashed $separate-color;
        }

        .user-info-content {
          display: flex;
          align-items: center;
          min-width: 140px;
          padding: 6px 10px;
          background-color: #213c87;
          border-radius: 30px;

          .avatar {
            display: block;
            width: 30px;
            margin-right: 10px;
          }

          .info {
            @include flexDirection();
            .username {
              color: #fff;
              font-size: 16px;
              font-weight: 400;
              white-space: nowrap;
              // text-transform: capitalize;
            }
            .role {
              color: #81A9F1;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }

    ::v-deep .el-cascader {
      opacity: 0;
    }
  }
</style>
