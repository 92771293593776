<template>
  <div class="map-page">
    <baidu-map
      class="map-container"
      :zoom="zoom"
      :center="location"
      :scroll-wheel-zoom="true"
      @ready="handleReady"
      :ak="BAI_DU_MAP_AK"
    >
      <bm-boundary
        :name="address_name"
        :strokeWeight="2"
        :fillOpacity="0.1"
        fillColor="#fff"
        strokeColor="#13c6de"
      />

      <template v-if="org_area_id === 610000">
        <bm-boundary
          v-for="(item,index) of cityList"
          :key="item.name"
          :name="item.name"
          :strokeWeight="1"
          :fillOpacity="0.1"
          fillColor="#fff"
          strokeColor="rgba(19, 198, 222, .3)"
        />
      </template>

      <dot-overlay
        v-for="(item,index) of markerList"
        :key="index"
        :color="item.color"
        :position="{lng: item.check_lng, lat: item.check_lat}"
        :index="index"
        :info="item"
        :type="type"
        :billows="item.billows"
      ></dot-overlay>

      <!-- 新增加会冒泡的点 -->
      <dot-overlay
        v-if="type === 1 && ECNewItemData"
        color="#25f485"
        :position="{lng: ECNewItemData.check_longitude, lat: ECNewItemData.check_latitude}"
        :index="999999"
        :info="ECNewItemData"
        :type="type"
        billows="rgba(37, 244, 133, .25)"
      ></dot-overlay>

<!--      <dot-overlay
        v-if="type === 1 && testData"
        color="#00ad45"
        :position="{lng: testData.check_lng, lat: testData.check_lat}"
        :index="999999"
        :info="testData"
        :type="type"
        billows="rgba(0, 193, 110, .45)"
      ></dot-overlay>-->

      <bm-point-collection
        :points="pointList"
        shape="BMAP_POINT_SHAPE_CIRCLE"
        color="rgba(255,0,0,.6)"
        size="BMAP_POINT_SIZE_SMALL"
        @mouseover="handleMouseOver"
        @mouseout="handleMouseOut"
      ></bm-point-collection>

      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>
    </baidu-map>

    <div class="hint-info-0" v-if="type === 0">
      <p class="hint-title">新增检查</p>
      <p class="green">
        <span></span>
        <span>1分钟之内</span>
      </p>
      <p class="yellow">
        <span></span>
        <span>10分钟之内</span>
      </p>
      <p class="orange">
        <span></span>
        <span>30分钟之内</span>
      </p>
      <p class="red">
        <span></span>
        <span>&gt;1小时</span>
      </p>
    </div>

    <div class="hint-info-1" v-if="type === 1">
      <span></span>
      <span>已核查主体</span>
    </div>
  </div>
</template>

<script>

  import {mapState} from "vuex"
  import {BAI_DU_MAP_AK} from "@/config/config"
  import BaiduMap from "@/components/vue-baidu-map/components/map/Map.vue"
  import BmBoundary from "@/components/vue-baidu-map/components/others/Boundary.vue"
  import BmMarker from "@/components/vue-baidu-map/components/overlays/Marker.vue"
  import BmPointCollection from "@/components/vue-baidu-map/components/overlays/PointCollection"
  import BmNavigation from "@/components/vue-baidu-map/components/controls/Navigation";
  import dotOverlay from "./dot-overlay"
  import mapStyleJSON from "./mapStyle.json"
  import addressList from "./cityZoom.json"
  import {formatDateForCurrent, formatTimeForColor, timeChunk} from "@/utils/index"


  export default {
    name: "map-container",
    components: {
      BaiduMap,
      BmBoundary,
      BmMarker,
      BmPointCollection,
      dotOverlay,
      BmNavigation,
    },
    data() {
      return {
        BAI_DU_MAP_AK,
        markerList: [],
        pointList: [],
        map: null,
        location: { lng: 0, lat: 0 },
        zoom: 15,
        address_name: '陕西省',
        timer: null,
        testData: {
          check_lng: "113.40349690755208",
          check_lat: "23.11317355685764",
          id: "1908",
        },
      }
    },

    computed: {
      ...mapState({
        org_area_id: state => state.user.org_area_id,
        address: state => state.address.address,
        type: state => state.electronic.type,
        ECNewItemData: state => state.electronic.ECNewItemData,
      }),

      cityList() {
        return addressList[0].children.map(item => ({'name': item.name}))
      },
    },

    watch: {
      address() {
        this.checkAddress()
        this.getECShopList();
      },

      type() {
        this.markerList = [];
        this.pointList = [];
        this.initGetShopDotData();
      },

      ECNewItemData(newVal) {
        console.log(newVal, "newVal")
        if (newVal !== null) {
          const {id, check_latitude, check_longitude} = newVal;
          this.pointList.unshift({ id, check_latitude, check_longitude });
        }
      },
    },

    async mounted() {
      await this.initGetShopDotData();
    },

    methods: {

      initGetShopDotData() {
        this.address_name = this.address;
        this.getAddressList();
        this.getECShopList();
      },

      async getAddressList() {
        if (this.type !== 0) return;
        const {data} = await this.$api.getCheckRecordRealTime({ org_area_id: this.org_area_id });
        const list = data
          .map((item) => {
            return {
              id: item.id,
              bill_no: item.bill_no,
              check_address: item.check_address,
              check_lat: item.check_latitude,
              check_lng: item.check_longitude,
              created_at: item.created_at,
              license_no: item.license_no,
              org_area_id: item.org_area_id,
              owner_name: item.owner_name,
              shop_address: item.shop_address,
              shop_name: item.shop_name,
              telephone: item.telephone,
              user_id: item.user_id,
              billows: "",
              get color() {
                const [fill, stroke] = formatTimeForColor(formatDateForCurrent(this.created_at));
                this.billows = stroke;
                return fill;
              },
            };
          });
        this.markerList = [];
        const renderCircleDOM = timeChunk(list, (item) => {
          this.type === 0 && this.markerList.push(item);
        })
        renderCircleDOM()
      },

      async getECShopList() {
        if (this.type !== 1) return;
        const {data} = await this.$api.getECShopList({ org_area_id: this.org_area_id });
        const list = data.map((item) => {
          return {
            id: item.id,
            lat: item.check_latitude,
            lng: item.check_longitude,
            license_no: item.unified_social_credit_code,
          };
        });

        this.pointList = list;
      },

      handleReady({ map, BMap }) {
        this.map = map;
        this.location.lng = 108.978804;
        this.location.lat = 34.347031;
        this.zoom = 8;
        this.map.setMapStyleV2({
          styleId: "912c3bdb9b561674e1d1087fc122352e",
          styleJson: mapStyleJSON,
        });
      },

      checkAddress() {
        this.address_name = this.address;
        this.checkZoom(addressList, this.org_area_id);
        this.getAddressList()
      },

      checkZoom(list=[], id) {
        if (list.length === 0) return;
        for (let item of list.values()) {
          if (+item.org_area_id === id) {
            this.zoom = item.zoom ? +item.zoom : 10;
            this.location.lng = item.lng;
            this.location.lat = item.lat;
            return;
          }
          this.checkZoom(item.children, id);
        }
      },

      handleMouseOver(current, {point}) {
        this.timer = setInterval(() => {
          this.$store.dispatch("getECShopCheckList", {e_c_retail_shop_id: point.id});
          this.$store.commit("setIsLookDotFlag", true);
          clearInterval(this.timer);
          this.timer = null;
        }, 2000);
      },

      handleMouseOut() {
        clearInterval(this.timer);
        this.timer = null;
        this.$store.commit("setIsLookDotFlag", false);
      },

    }

  }
</script>

<style scoped lang="scss">
  .map-page {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #133067;

    .map-container {
      width: 100%;
      height: 100%;
      background-color: #133067;
    }

    .hint-info-0 {
      position: absolute;
      bottom: 3%;
      left: 3%;
      padding: 10px;
      background-color: rgba(10, 36, 88, .3);
      font-size: 12px;
      font-weight: 400;
      color: $mini-font-color;

      .hint-title {
        font-size: 14px;
      }



      p {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 6px;

        span:first-child {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }

      .green span:first-child {
        background-color: #25f485;
      }
      .yellow span:first-child {
        background-color: #fcec06;
      }
      .orange span:first-child {
        background-color: #ff9314;
      }
      .red span:first-child {
        background-color: #f00;
      }
    }

    .hint-info-1 {
      @extend .hint-info-0;
      display: flex;
      align-items: center;

      span:first-child {
        width: 10px;
        height: 10px;
        background-color: #f00;
        border-radius: 50%;
      }

      span:last-child {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 400;
        color: $mini-font-color;
      }
    }
  }

  ::v-deep .anchorBL {
    display: none;
  }
</style>
