<template>
  <div class="page">
    <div class="top">
      <div class="inspect">
        <div class="inspect_info">
          <countTo class="text" :startVal="0" :endVal="electronicchart.total_e_c_shop_num"/>
          <div class="text_two">需核查主体总数</div>
        </div>
        <div class="inspect_info">
          <countTo class="text" :startVal="0" :endVal="electronicchart.shop_not_check_num"/>
          <div class="text_two">待核查</div>
        </div>
        <div class="inspect_info">
          <div>
            <div class="decline">
              <countTo style="margin-right:20px" class="text" :startVal="0"
                       :endVal="NearFutureCheckShopNum.nearly_seven_day_check_num"/>
              <span class="decline_text">环比</span>
              <span class="decline_text">{{ NearFutureCheckShopNum.link_relative_ratio }}</span>
              <img class="decline_img"
                   :src="$currency.ossFileURL(`${NearFutureCheckShopNum.color=='red'?arrow_down:arrow_up}`)" alt="">
            </div>
          </div>
          <div class="text_two">近7天核查主体数量</div>
        </div>
      </div>

    </div>

    <div class="managementTypeTwo">
      <div class="bottom">
        <s-section-title-bar>各下级单位电子烟生产经营主体数量</s-section-title-bar>
      </div>
      <div id="managementCityChar" class="managementCity_Char"></div>
    </div>

    <div class="hecha">
      <div class="bottom">
        <s-section-title-bar>各下级单位电子烟生产经营主体信息核查情况分析</s-section-title-bar>
      </div>
      <div class="chart" id="electronic-chart">

      </div>
    </div>
  </div>
</template>

<script>
import {Charts} from "@/views/home/charts";
import {mapState} from "vuex"

export default {
  name: 'electronic',
  data() {
    return {
      arrow_down: 'arrow-down',
      arrow_up: 'arrow-up',
      pageSize: 4, //每页几行
      startPage: 1, //起始页
      totalUsers: 30, //总行数
    }
  },

  computed: {
    ...mapState({
      org_area_id: state => state.user.org_area_id,
      electronicchart: state => state.electronic.electronicchart,
      SubordinateUnitCheckSituation: state => state.electronic.SubordinateUnitCheckSituation,
      NearFutureCheckShopNum: state => state.electronic.NearFutureCheckShopNum,
      managementCityChar: state => state.electronic.managementCityChar,
    })
  },

  watch: {
    SubordinateUnitCheckSituation(newVal) {
      Charts.initelectronicchart('electronic-chart', newVal);
    },
    managementCityChar(newVal) {
      Charts.initmanagementCityChar('managementCityChar', newVal);
    },
    org_area_id(newVal) {
      this.$store.dispatch('getSubordinateUnitCheckSituation', {org_area_id: this.$store.getters.parent_id || newVal})
      this.$store.dispatch('getSubordinateUnitECShopNum', {org_area_id: this.$store.getters.parent_id || newVal})
      this.$store.dispatch('getECShopCheckSituation', {org_area_id: this.org_area_id});
      this.$store.dispatch('getNearFutureCheckShopNum', {org_area_id: this.org_area_id});
    },

  },
}
</script>

<style lang="scss" scoped>

.page {

}

.top {
  height: 150px;
  margin-left: -30px;
  background-image: url('https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/kuang.png');
  background-size: 98% 150px;
  background-repeat: no-repeat;
}

.inspect {
  height: 120px;
  width: 98%;
  display: flex;
  justify-content: space-around;
  align-content: center;
}

.inspect_info {
  margin-top: 20px;
  line-height: 50px;
  // text-align: center;
  display: flex;
  flex-direction: column;
}

.text {
  font-size: 20px;
  font-weight: normal;
  color: #FFF;
}

.text_two {
  color: #81A9F1;
  font-size: 14px;
  font-weight: 400;
}

.decline {
  // height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // margin-right: 40px;
}

.decline_text {
  color: #9DB9EB;
  font-size: 12px;
  font-weight: 400;
  margin-right: 20px;
}

.decline_img {
  width: 15px;
  height: 20px;
}

.bottom {
  margin-top: 20px;

}

.hecha {
  margin-left: -25px;
  // margin-top: 35px;
  // width: 100%;
}

#electronic-chart {
  margin-top: 20px;
  width: 100%;
  height: 360px;
  font-size: 14px;
  font-weight: 400;
}

.managementTypeTwo {
  margin-left: -25px;
  margin-top: 25px;
}

.managementCity_Char {
  // margin-top: 10px;
  width: 100%;
  height: 350px;
  font-size: 14px;
  font-weight: 400;
}
</style>
<style scoped>


</style>
