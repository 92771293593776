<template>
  <div class="person-ranking">
    <div class="top-title">
      <s-section-title-bar>稽查员排名</s-section-title-bar>
      <div class="ranking-period">排名周期: {{ rankingCycleTime || "-" }}</div>
    </div>


    <div class="person-table">

      <div class="table-head">
        <div>稽查员</div>
        <div>所属单位</div>
        <div>账号</div>
        <div>综合得分</div>
      </div>

      <div class="table-item" v-for="(item,index) of personRankingList" :key="item.id">
        <div class="ranking-num" :class="[`ranking-num-${index}`]">{{ item.rank }}</div>
        <div class="person-info">
          <div class="examine-person">{{ item.realname }}</div>
          <div class="subjection-unit">{{ item.address }}</div>
          <div class="person-phone">{{ item.phone }}</div>
          <div class="synthesis-score">{{ item.score }}</div>
        </div>
      </div>

      <div class="hint-text" v-if="personRankingList.length === 0">
        未更新排名数据，每个排名周期（即每月）的第二天开始更新排名数据
      </div>

    </div>

  </div>
</template>

<script>
  import {mapState} from "vuex"
  export default {
    name: "person-ranking",
    data() {
      return {}
    },

    computed: {
      ...mapState({
        personRankingList: state => state.routine.personRankingList,
        rankingCycleTime: state => state.routine.rankingCycleTime
      })
    },

  }
</script>

<style lang="scss" scoped>

  @import "../../../../styles/mixin";

  .person-ranking {
    width: 100%;
    padding: 0 19px;
    margin-top: 30px;

    .top-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;

      .ranking-period {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        color: #C0CCE4;
      }

    }

    .person-table {
      margin-top: 10px;

      .table-head {
        display: flex;
        align-items: center;
        //justify-content: space-between;
        width: 100%;
        padding-left: 26px;
        font-size: 14px;
        font-weight: 400;
        color: #8799C0;
        text-align: center;
        div:nth-child(1) {
          width: 22%;
          padding-right: 4%;
        }
        div:nth-child(2) {
          width: 38%;
          padding-right: 55px;
        }
        div:nth-child(3) {
          width: 20%;
        }
        div:nth-child(4) {
          width: 20%;
          color: #E8EBF0;
        }
      }

      .table-item {
        $font-color: #C0CCE4;
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: $font-color;

        & + .table-item {
          margin-top: 11px;
        }

        .ranking-num {
          width: 16px;
          height: 16px;
          color: #EEF4F6;
          margin-right: 10px;
          text-align: center;
          line-height: 17px;
          border-radius: 50%;

          &.ranking-num-0 {
            color: #0C1130;
            background-color: #eeb13d;
          }
          &.ranking-num-1 {
            color: #0C1130;
            background-color: #4390d6;
          }
          &.ranking-num-2 {
            color: #0C1130;
            background-color: #7f4c18;
          }
        }

        .person-info {
          flex: 1;
          display: flex;
          align-items: center;
          //justify-content: space-between;
          .examine-person {
            @include ellipsis(1);
            width: 22%;
            padding-right: 4%;
            text-align: center;
          }
          .subjection-unit {
            @include ellipsis(1);
            width: 38%;
          }
          .person-phone {
            @include ellipsis(1);
            width: 20%;
            text-align: center;
          }
          .synthesis-score {
            @include ellipsis(1);
            width: 20%;
            color: #EEF4F6;
            text-align: center;
          }
        }

      }

      .hint-text {
        width: 100%;
        height: 250px;
        font-weight: 400;
        font-size: 14px;
        line-height: 250px;
        color: $medium-font-color;
        text-align: center;
      }

    }

  }
</style>
