<template>
  <div class="left-container">

    <electronic-left/>

  </div>
</template>

<script>
import electronicLeft from "./child/electronic_left";

export default {
  name: "left",
  components: {
    electronicLeft,
  },
}
</script>

<style lang="scss" scoped>
  @import "../../../styles/mixin";
  $left-container-bg: url('http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg-left.png');

  .left-container {
    position: relative;
    z-index: 2;
    width: 27.4%;
    height: 100%;
    padding: 73px 0 0 40px;
    background-size: 100% auto;
    background-image: $left-container-bg;
    background-repeat: no-repeat;

  }
</style>
